import { useEffect, useState } from 'react';

import useFetch from './graphql/useFetch';
import { GET_COMBINED_RESULTS_AGE_GROUP, GET_COMBINED_RESULTS_COMPETITIONS_LANES } from '../utils/graphql/queries';
import { COMBINED_RESULTS_COMPETITION } from '../utils/graphql/subscriptions';
import useConditionalSub from './graphql/useConditionalSub';

/**
 * This hook is used for getting the frame of the Competition Summary, where the 'frame' is the SummaryType object but with only
 * the id and updated_at properties, and additionally the rank property to correctly sort the ranks.
 * @param timeProgramId  
 * @param summaryTypes 
 * @param eventLevel 
 * @returns Object, ( summaryData, loading, error, sortedRanks, changeSummaryType, summaryTypeIndex, summaryTypeId)
 */

export default function useCombinedResults(combinedGroup: number | undefined, eventNumber: number | undefined, roundType: number | undefined, combinedType: number | undefined, condition: boolean, eventView: View) {

    const [availableAgeGroups, setAvailableAgeGroups] = useState<AgeGroup[]>([]);
    const [selectedAgeGroup, setSelectedAgeGroup] = useState<AgeGroup | undefined>(undefined);
    const [ageGroupTabIndex, setAgeGroupTabIndex] = useState<number>(0);
    const [lanes, setLanes] = useState<LaneWithRank[] | undefined>(undefined);

    const { data: combinedSub, endSub: unsub, restartSub } = useConditionalSub<CombinedCompetition[]>(COMBINED_RESULTS_COMPETITION, { group_number: combinedGroup, event_number: eventNumber, round_type: roundType }, condition && eventView === 'combined');
    const { data: combinedData, error, loading, refresh } = useFetch<CombinedCompetition[]>(combinedType === 0 ? GET_COMBINED_RESULTS_COMPETITIONS_LANES : GET_COMBINED_RESULTS_AGE_GROUP, { group_number: combinedGroup, event_number: eventNumber, round_type: roundType }, 'combinedGroup', 'cache-and-network', (!combinedGroup || !eventNumber || !roundType), false);

    useEffect(() => {
        refresh();
    }, [combinedSub]);

    useEffect(() => { if (eventView !== 'combined') { unsub(); } else { restartSub(); } }, [eventView]);

    useEffect(() => {
        setSelectedAgeGroup(availableAgeGroups[ageGroupTabIndex]);
    }, [ageGroupTabIndex]);

    useEffect(() => {
        if (combinedData && combinedData[0]?.competition?.events && combinedData[0]?.competition?.events[0]) {
            const ageGroups = combinedData[0].competition?.events[0]?.agegroups;
            setAvailableAgeGroups(ageGroups || []);

            if (combinedData[0].competition?.events[0]?.agegroups && combinedData[0].competition?.events[0]?.agegroups?.length >= ageGroupTabIndex - 1) {
                setSelectedAgeGroup(combinedData[0].competition?.events[0]?.agegroups[ageGroupTabIndex]);
            } else {
                ageGroups && setSelectedAgeGroup(ageGroups[0]);
                setAgeGroupTabIndex(0);
            }
        }
    }, [combinedData]);

    useEffect(() => {
        if (availableAgeGroups.length > 1 && selectedAgeGroup !== null) {
            let filterLanes: LaneWithRank[] = [];
            let nonResultsFiltered: LaneWithRank[] = [];
            combinedData?.map(combined_comp => {
                //@ts-ignore
                filterLanesOnAgeGroup(combined_comp?.competition?.events[0]?.rounds, filterLanes, nonResultsFiltered, selectedAgeGroup);
            });
            let results = sortAllLanes(filterLanes, nonResultsFiltered);
            setLanes(results);
        } else {
            let filterLanes: LaneWithRank[] = [];
            let nonResultsFiltered: LaneWithRank[] = [];
            combinedData?.map(combined_comp => {
                //@ts-ignore
                getLanesOfCompetition(combined_comp?.competition?.events[0]?.rounds, filterLanes, nonResultsFiltered);
            });
            let results = sortAllLanes(filterLanes, nonResultsFiltered);
            setLanes(results);
        }
    }, [selectedAgeGroup, combinedData]);

    return { combinedData, lanes, error, loading, selectedAgeGroup, setSelectedAgeGroup, ageGroupTabIndex, setAgeGroupTabIndex, unsub, restartSub };
}

const filterLanesOnAgeGroup = (rounds: Round[], filterLanes: Lane[], nonResultsFiltered: Lane[], selectedAgeGroup: AgeGroup) => {
    if (rounds !== undefined) {
        rounds[0]?.heats?.map(heat => {
            if (heat.status === 3) {
                heat?.lanes?.map(lane => {
                    //@ts-ignore
                    if (lane?.competitor?.age >= selectedAgeGroup?.from_age && lane?.competitor?.age <= selectedAgeGroup.to_age) {
                        //@ts-ignore
                        if (lane.result_value > 0 && lane.competitor?.first_name !== undefined && !lane.dsq && !lane.dns && !lane.dnf) {
                            filterLanes.push(lane);
                        } else if (lane.competitor?.last_name !== undefined) {
                            nonResultsFiltered.push(lane);
                        }
                    }
                });
            }
        });
    }
};

const getLanesOfCompetition = (rounds: Round[], allLanes: LaneWithRank[], nonResults: LaneWithRank[]) => {
    if (rounds !== undefined) {
        rounds[0]?.heats?.map(heat => {
            if (heat.status === 3) {
                heat.lanes?.map(lane => {
                    //@ts-ignore
                    if (lane.result_value > 0 && lane.competitor?.first_name !== undefined && !lane.dsq && !lane.dns && !lane.dnf) {
                        allLanes.push(lane);
                    } else if (lane.competitor?.first_name !== undefined) {
                        nonResults.push(lane);
                    }
                });
            }
        });
    }
};

const sortAllLanes = (allLanes: LaneWithRank[], nonResults: LaneWithRank[]) => {
    let offset = 1;
    let previousRank = 1;
    let sortedLanes = allLanes.sort((a, b) => {
        //@ts-ignore
        if (a.result_value > b.result_value) {
            return 1;
        }
        //@ts-ignore

        if (a.result_value < b.result_value) {
            return -1;
        }
        return 0;
    });
    let rankCount = 1;
    let finalList: LaneWithRank[] = [];
    sortedLanes.map((lane, index) => {

        let temp = {
            ...lane,
            rank: (index > 0 && lane.result_value === sortedLanes[index - 1].result_value) ? previousRank : rankCount
        };
        previousRank = rankCount;

        if (index > 0) {
            if (lane.result_value === sortedLanes[index - 1].result_value) offset++;
            else offset = 1;
        }
        if (index + 1 < sortedLanes.length && lane.result_value !== sortedLanes[index + 1].result_value) rankCount += offset;

        finalList.push(temp);

    });
    let results = finalList.concat(nonResults);
    return results;
};
