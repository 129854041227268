import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import "./liveCompetitionCard.css";
import { FlagComponent, StatusBadge } from "../layout";
import { storeCompetitionId } from "../utils";
import SuperliveLogo from "../svg/SuperliveLogo";

export default function LiveCompetitionCard({ competition, cursor, index }) {
  const currentDate = new Date().toLocaleDateString("sv-SE");

  const navigate = useNavigate();

  const { id, startDate, endDate, name, seo_text, organizer, superlive } =
    competition;

  const [liveCompetition, setLiveCompetition] = useState(false);

  const smallCompImg = competition?.small_image;

  const dateArr = startDate.split("-");
  const year = dateArr[0];
  const day = dateArr[2];
  // const endDay = endDate.split("-")[2]; // Show span of days if more than 1?

  const monthName = new Date(startDate).toLocaleString("en-us", {
    month: "short",
  });

  const desktop = useMediaQuery("(min-width:900px)");

  useEffect(() => {
    if (startDate <= currentDate && endDate >= currentDate) {
      setLiveCompetition(true);
    }
  }, []);

  const handleNavigation = () => {
    if (window.location.hostname === "test.swimify.com")
      return (window.location.href = `https://test.swimify.com/competitions/${seo_text}`);
    if (competition?.database_region === 2)
      return (window.location.href = `https://live.swimify.com/competitions/${seo_text}`);
    if (competition?.database_region === 3)
      return (window.location.href = `https://au.swimify.com/competitions/${seo_text}`);
    else navigate(`/competitions/${seo_text}`);
  };

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      height={60}
      py={{ xs: 0.5, md: 1 }}
      sx={{
        width:
          "100%" /* { xs: 'calc(100vw - 16px)', md: 'calc(100vw - 32px)' } */,
        maxWidth: 1600,
        cursor: cursor ?? "pointer",
        borderTopStyle: index !== 0 && "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
      }}
      onClick={() => {
        storeCompetitionId(seo_text, id);
        handleNavigation();
      }}
    >
      <Grid container item xs={6} width="100%" sx={{ bgcolor: "" }}>
        <Box
          width={88}
          height={44}
          ml={{ xs: 0.5, md: 1 }}
          sx={{ borderRadius: 1, boxShadow: 3 }}
        >
          <Box
            component="img"
            width={88}
            height={44}
            src={
              smallCompImg
                ? smallCompImg
                : "/img/competition-img-small-placeholder.png"
            }
            sx={{ borderRadius: 1 }}
          />
        </Box>
        <Box
          width={{ xs: 36, sm: 44, md: 60 }}
          height={{ xs: 36, sm: 44 }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          my="auto"
          sx={{ bgcolor: "" }}
        >
          <Typography
            sx={{
              textTransform: "uppercase",
              fontWeight: 300,
              fontSize: { xs: "0.55rem", sm: "0.7rem", md: "0.75rem" },
              lineHeight: 1,
            }}
          >
            {monthName}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "0.7rem", sm: "0.9rem", md: "1rem" },
              lineHeight: 1,
              mt: 0.1,
            }}
          >
            {day}
          </Typography>
          <Typography
            sx={{
              fontWeight: 300,
              fontSize: { xs: "0.6rem", sm: "0.7rem", md: "0.8rem" },
              lineHeight: 1,
            }}
          >
            {year}
          </Typography>
        </Box>
        <Box
          width={{
            /* xs: 'calc(50vw - 140px)', sm: 'calc(50vw - 152px)', md: 'calc(50vw - 180px)' */ xs: "calc(100% - 140px)",
            sm: "calc(100% - 152px)",
            md: "calc(100% - 180px)",
          }}
          maxWidth={620}
          my="auto"
          sx={{ bgcolor: "" }}
        >
          <Typography
            noWrap
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "0.75rem", sm: "1rem", md: "1.2rem" },
              lineHeight: 1,
            }}
          >
            {name}
          </Typography>
        </Box>
      </Grid>
      <Grid container item xs={6} width="100%" sx={{ bgcolor: "" }}>
        <Grid container item xs={6} gap={{ xs: 0.5, sm: 1 }} my="auto">
          <Box
            sx={{
              height: "44px",
              width: "44px",
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Box
              component="img"
              width={44}
              height={44}
              src={
                competition?.organizer_logo
                  ? competition?.organizer_logo
                  : "/img/img-placeholder-square_48.png"
              }
              sx={{ borderRadius: 2 }}
            />
          </Box>
          <Box
            width={{
              // xs: 'calc(50vw - 100% - 52px)', sm: 'calc(50vw - 100% - 68px)', md: 'calc(50vw - 100% - 76px)'
              xs: "calc(100% - 52px)",
              sm: "calc(100% - 68px)",
              md: "calc(100% - 76px)",
            }}
            maxWidth={340}
            my="auto"
            sx={{ bgcolor: "" }}
          >
            <Typography
              noWrap
              lineHeight={1}
              sx={{
                fontWeight: 300,
                fontSize: { xs: "0.5rem", sm: "0.65rem", md: "0.9rem" },
              }}
            >
              {organizer}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} my="auto" sx={{ bgcolor: "" }}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap={{ xs: 0.5, sm: 1 }}
            sx={{ bgcolor: "" }}
          >
            <Box
              width={{ xs: 20, md: 32 }}
              height={{ xs: 15, md: 24 }}
              sx={{ boxShadow: 3, borderRadius: 2, bgcolor: "" }}
            >
              <FlagComponent
                countryCode={competition?.nation_code}
                size={desktop ? "l" : "m"}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={superlive ? 0.5 : 0.125}
              width={{
                xs: "calc(100% - 32px)",
                sm: "calc(100% - 32px)",
                md: "calc(100% - 48px)",
              }}
              // maxWidth={352} 
              sx={{ bgcolor: "" }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                noWrap
                width={{
                  xs: liveCompetition ? "calc(100% - 44px)" : "100%",
                  sm: liveCompetition ? "calc(100% - 60px)" : "100%",
                  md: liveCompetition ? "calc(100% - 62px)" : "100%",
                }}
                maxWidth={{
                  xs: liveCompetition ? `calc(100% - ${44 + 32}px)` : "none",
                  sm: liveCompetition ? `calc(100% - ${44 + 32}px)` : "none",
                  md: liveCompetition ? `calc(100% - ${44 + 32}px)` : "none", 
                }}
                /* maxWidth={liveCompetition ? 288 : 352} */ sx={{
                  bgcolor: "",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                <Typography
                  noWrap
                  lineHeight={1}
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "0.6rem", sm: "0.75rem", md: "0.9rem" },
                  }}
                >
                  {competition?.pool_name}
                </Typography>
                <Typography
                  noWrap
                  lineHeight={1}
                  sx={{
                    fontWeight: 300,
                    fontSize: { xs: "0.5rem", sm: "0.65rem", md: "0.75rem" },
                  }}
                >
                  {competition?.city}
                </Typography>
              </Box>
              {superlive && (
                <SuperliveLogo width={32} height={32} fill="#3C5265" />
              )}
              {/* uncomment to test what multiple badges will look like */}
              {/* <StatusBadge status={"LIVE"} /> */}
              {liveCompetition && <StatusBadge status={"LIVE"} />}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
