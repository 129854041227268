import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Typography, Box, Divider, Grid, useMediaQuery } from "@mui/material";
import { AUClient, EUClient } from "../../../utils/apollo/apollo-client";
import {
  GET_FILTERED_FINISHED_COMPETITIONS,
  GET_FINISHED_COMPETITIONS,
} from "../../../utils/graphql/queries";
import { NoCompetitionsLink } from "../../../components/layout";
import QueryResult from "../../../components/containers/QueryResult";
import CompetitionCard from "../../../components/containers/CompetitionCard";

export default function FinishedCompetitions({
  selectedRegion,
  selectedCountry,
}) {
  const currentDate = new Date().toLocaleDateString("sv-SE");

  const [euCompetitions, setEuCompetitions] = useState([]);
  const [auCompetitions, setAuCompetitions] = useState([]);
  const [combinedCompetitions, setCombinedCompetitions] = useState([]);
  const [filteredCompetitions, setFilteredCompetitions] = useState([]);

  const handleHeader = () => {
    if (selectedRegion === 1) {
      if (combinedCompetitions.length < 1) {
        return "No Finished Competitions";
      } else return "Finished Competitions";
    } else {
      if (filteredCompetitions.length < 1) {
        return "No Finished Competitions";
      } else return "Finished Competitions";
    }
  };

  // fetch competitions with endDate lesser than currentDate
  const [euCompetitionFetch, { loading: euLoading }] = useLazyQuery(
    GET_FINISHED_COMPETITIONS,
    {
      client: EUClient,
      fetchPolicy: "network-only",
      variables: { _lt: currentDate },
      onCompleted: (data) => {
        const euComps = data?.competitions.map((competition) => ({
          ...competition,
          database_region: 2,
        }));
        setEuCompetitions(euComps);
      },
    }
  );

  const [auCompetitionFetch, { loading: auLoading }] = useLazyQuery(
    GET_FINISHED_COMPETITIONS,
    {
      client: AUClient,
      fetchPolicy: "network-only",
      variables: { _lt: currentDate },
      onCompleted: (data) => {
        const auComps = data?.competitions.map((competition) => ({
          ...competition,
          database_region: 3,
        }));
        setAuCompetitions(auComps);
      },
    }
  );

  const [filteredFetch, { loading: filteredLoading, data: filteredData }] =
    useLazyQuery(GET_FILTERED_FINISHED_COMPETITIONS, {
      client: selectedRegion === 2 ? EUClient : AUClient,
      fetchPolicy: "network-only",
      variables: {
        _lt: currentDate,
        nation: selectedCountry,
      },
      onCompleted: (data) => {
        const filteredComps = data?.competitions.map((competition) => ({
          ...competition,
          database_region: selectedRegion,
        }));
        setFilteredCompetitions(filteredComps);
      },
    });

  /* HANDLE COMBINED FETCH RESULTS */
  useEffect(() => {
    if (euCompetitions?.length > 0 && auCompetitions?.length > 0) {
      const combinedResults = [...euCompetitions, ...auCompetitions];
      const sortedCombinedResults = combinedResults.sort(
        (a, b) =>
          (new Date(b.startDate).getTime() || -Infinity) -
          (new Date(a.startDate).getTime() || -Infinity)
      );
      sortedCombinedResults?.length > 0 &&
        setCombinedCompetitions(sortedCombinedResults);
    } else if (euCompetitions?.length > 0 && auCompetitions?.length < 1) {
      setCombinedCompetitions(euCompetitions);
    } else if (euCompetitions?.length < 1 && auCompetitions?.length > 0) {
      setCombinedCompetitions(auCompetitions);
    }
  }, [euCompetitions, auCompetitions]);

  /* HANDLE REGION CHANGE */
  useEffect(() => {
    if (selectedRegion === 1) {
      euCompetitionFetch();
      auCompetitionFetch();
    } else filteredFetch();
  }, [selectedRegion]);

  const isDesktop = useMediaQuery("(min-width: 900px)");

  const loading = (!combinedCompetitions && euLoading) || auLoading;

  return (
    <Grid item xs={12} md={6}>
      <Box sx={{ py: 1, background: "linear-gradient(#317A4D, #255C3A)" }}>
        {/* check if competitions array is empty */}
        <Typography
          mx={1}
          my="auto"
          fontSize="1.2rem"
          fontWeight={600}
          color="#FFF"
        >
          {handleHeader()}
        </Typography>
      </Box>
      <Divider />
      <Box>
        <QueryResult
          loading={loading}
          data={
            selectedRegion === 1 ? combinedCompetitions : filteredCompetitions
          }
        >
          {selectedRegion === 1 &&
            combinedCompetitions?.length < 1 &&
            euCompetitions?.length > 0 &&
            euCompetitions?.map(
              (competition, index) =>
                index < 21 && (
                  <Box
                    key={competition?.id}
                    sx={{
                      background:
                        index % 2
                          ? "#FFF"
                          : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                      boxShadow: isDesktop ? "-1px 0 0 #D0D5DD" : "",
                    }}
                  >
                    <CompetitionCard competition={competition} index={index} />
                  </Box>
                )
            )}
          {selectedRegion === 1 &&
            combinedCompetitions?.length < 1 &&
            auCompetitions?.length > 0 &&
            auCompetitions?.map(
              (competition, index) =>
                index < 21 && (
                  <Box
                    key={competition?.id}
                    sx={{
                      background:
                        index % 2
                          ? "#FFF"
                          : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                      boxShadow: isDesktop ? "-1px 0 0 #D0D5DD" : "",
                    }}
                  >
                    <CompetitionCard competition={competition} index={index} />
                  </Box>
                )
            )}
          {selectedRegion === 1 &&
            combinedCompetitions?.length > 0 &&
            combinedCompetitions?.map(
              (competition, index) =>
                index < 21 && (
                  <Box
                    key={competition?.id}
                    sx={{
                      background:
                        index % 2
                          ? "#FFF"
                          : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                      boxShadow: isDesktop ? "-1px 0 0 #D0D5DD" : "",
                    }}
                  >
                    <CompetitionCard competition={competition} index={index} />
                  </Box>
                )
            )}
          {selectedRegion === 1 && combinedCompetitions?.length < 1 && (
            <NoCompetitionsLink title="No Finished Competitions." />
          )}
          {selectedRegion !== 1 &&
            filteredCompetitions?.length > 0 &&
            filteredCompetitions?.map(
              (competition, index) =>
                index < 21 && (
                  <Box
                    key={competition?.id}
                    sx={{
                      background:
                        index % 2
                          ? "#FFF"
                          : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                      boxShadow: isDesktop ? "-1px 0 0 #D0D5DD" : "",
                    }}
                  >
                    <CompetitionCard competition={competition} index={index} />
                  </Box>
                )
            )}
          {selectedRegion !== 1 && filteredCompetitions?.length < 1 && (
            <NoCompetitionsLink title="No Finished Competitions." />
          )}
        </QueryResult>
      </Box>
    </Grid>
  );
}
