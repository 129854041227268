/**
 * Calculate the time difference between the result time and the entry time.
 * @param entryTime Number - milliseconds
 * @param resultTime Number - milliseconds
 * @returns String - seconds
 */

export default function entryResultDiff(entryTime: number, resultTime: number): string | undefined{
    const secondsDiff = ((resultTime - entryTime) / 1000).toFixed(2);
    const testNumber = Number(secondsDiff);
    if ( testNumber < 0 ) return `(${secondsDiff})`;
    if ( testNumber > 0 ) return `(+${secondsDiff})`;
    return undefined;
}