import React, { useState } from 'react';

import { Box, Button } from '@mui/material';

import RecordListItem from './RecordListItem';

interface props {
    eventRecords: EventRecord[];
}

export default function RecordBox({ eventRecords }: props) {

    const [showMore, setShowMore] = useState<boolean>(false);

    return (
        <>
            <Box sx={{ bgcolor: '', marginLeft: 0.5, marginRight: 0.5, borderTop: '1px solid #D0D5DD', borderBottom: eventRecords.length > 2 ? 'none' : '1px solid #D0D5DD', /* boxShadow: eventRecords.length > 2 ? "inset 0 -10px 10px -10px rgba(0,0,0,0.25)" : "none",  */height: eventRecords.length > 2 ? showMore ? "auto" : 125 : "auto", overflow: "hidden" }}>
                {/*eventRecords && eventRecords.filter((_, i) => i < 4).map(rec =>
                    <RecordListItem key={rec.id} record={rec} />
                )*/}
                {eventRecords && eventRecords.filter((_, i) => i >= 0).map(rec =>
                    <RecordListItem key={rec.id} record={rec} />
                )}
            </Box >
            {
                eventRecords.length > 2 &&
                <Box sx={{ borderTop: '1px solid #D0D5DD', marginLeft: 0.5, marginRight: 0.5, boxShadow: eventRecords.length > 2 && !showMore ? "0 -10px 10px 10px rgba(255, 255, 255, 0.8)" : "none", }}>
                    <Button
                        //@ts-ignore
                        variant='grey'
                        sx={{ fontSize: '0.75rem', mx: 1, mt: 1 }}
                        onClick={() => setShowMore(prevState => !prevState)}>{!showMore ? 'Show more' : 'Show less'}
                    </Button>
                </Box>
            }
        </>
    );
}