import { Box, Typography } from "@mui/material";

type Status =
  | "LATE"
  | "RES"
  | "EXH"
  | "S"
  | "L"
  | "Y"
  | "S1"
  | "S2"
  | "S3"
  | "S4"
  | "S5"
  | "S6"
  | "S7"
  | "S8"
  | "S9"
  | "S10"
  | "S11"
  | "S12"
  | "S13"
  | "S14"
  | "S15"
  | "S16"
  | "S17"
  | "S18"
  | "S19"
  | "SB1"
  | "SB2"
  | "SB3"
  | "SB4"
  | "SB5"
  | "SB6"
  | "SB7"
  | "SB8"
  | "SB9"
  | "SB10"
  | "SB11"
  | "SB12"
  | "SB13"
  | "SB14"
  | "SB15"
  | "SB16"
  | "SB17"
  | "SB18"
  | "SB19"
  | "SM1"
  | "SM2"
  | "SM3"
  | "SM4"
  | "SM5"
  | "SM6"
  | "SM7"
  | "SM8"
  | "SM9"
  | "SM10"
  | "SM11"
  | "SM12"
  | "SM13"
  | "SM14"
  | "SM15"
  | "SM16"
  | "SM17"
  | "SM18"
  | "SM19"
  | "K1"
  | "K2"
  | "K3"
  | "K4";

export default function EntriesTimeDisplay({
  eventType,
  entry,
  competitionPoolType,
}: {
  eventType: number | undefined;
  entry: Entry | undefined;
  competitionPoolType: number | undefined;
}) {

  const statusPrint = (entry: Entry | undefined) => {
    const statusArr: Status[] = [];
    if (entry?.entry_pool !== competitionPoolType) {
      switch (entry?.entry_pool) {
        case 1:
          statusArr.push("S");
          break;
        case 2:
          statusArr.push("L");
          break;
        case 4:
          statusArr.push("Y");
          break;
        default:
          break;
      }
    }
    if (entry?.late_entry) statusArr.push("LATE");
    if (entry?.reserve) statusArr.push("RES");
    if (entry?.not_in_competition) statusArr.push("EXH");

    if (statusArr?.length > 1) {
      const string = statusArr.join(", ");
      return (
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Box display="flex" width={66} justifyContent="flex-end">
            <Typography fontWeight={500}>{entry?.entry_time_text}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-start">
            <Typography ml="8px" fontWeight={400} fontSize="0.8rem">
              {string}
            </Typography>
          </Box>
        </Box>
      );
    } else if (statusArr?.length === 1) {
      return (
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Box
            display="flex"
            width={66}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography fontWeight={500}>{entry?.entry_time_text}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-start">
            <Typography ml="8px" fontWeight={400} fontSize="0.8rem">
              {statusArr[0]}
            </Typography>
          </Box>
        </Box>
      );
    } else
      return (
        <Box
          display="flex"
          width={66}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography fontWeight={500}>{entry?.entry_time_text}</Typography>
        </Box>
      );
  };

  return statusPrint(entry);
}
