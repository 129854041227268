import React, { useContext, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ScreenContext } from '../../../../../context/ScreenContext';


import {
    Typography,
    Box,
    Grid,
} from '@mui/material';

import { BsPeopleFill } from 'react-icons/bs';

import { competitorNameString, competitorNavigationString, entryResultDiff, getBirthYear, summaryTimeDisplay } from '../../../../../components/utils';
import { FlagComponent } from '../../../../../components/layout';
import { CountryCode } from '../../../../../components/layout/FlagComponent';
import { determineParaCode } from '../../../../../components/utils/determineParaCodes';

export default function CombinedResultsRow({ compId, rank, lane, index, event, eventLevel, setView, setSelectedCompetitor, showAge }: { compId: string | undefined, rank?: number, lane?: LaneWithRank, index: number, event?: Event, eventLevel: number, setView?: React.Dispatch<React.SetStateAction<number>>, setSelectedCompetitor?: React.Dispatch<React.SetStateAction<Competitor | undefined>>, showAge: boolean | undefined; }) {

    const { setScreen } = useContext(ScreenContext);
    const navigate = useNavigate();
    const params = useParams();

    const competitor = lane?.competitor;
    const competition = lane?.competition;

    const [imgLoaded, setImgLoaded] = useState<boolean>(true);

    const checkLaneStatus = (lane: Lane) => {
        if (lane?.dns || lane?.dnf || lane?.dsq || !lane?.entry_time_text) return null;
        return `Entry: ${lane?.entry_time_text}`;
    };

    return (
        <Grid container item display='flex' xs={12} alignItems='center' justifyContent="space-between" mt={0} py={0.5} 
        // onClick={() => {
        //     navigate(competitorNavigationString(params?.competitionName, competitor?.club?.oid, competitor?.oid));
        //     /* check if in eventView or competitorView */
        //     if (params.clubView) {
        //         setView && setView(3);
        //         setSelectedCompetitor && setSelectedCompetitor(competitor);
        //     } else setScreen('swimmers');
        // }}
            //@ts-ignore
            sx={{
                bgcolor: '#FFF', /* cursor: 'pointer',*/ borderTopStyle: index !== 0 && 'solid', borderWidth: 1, borderColor: '#D0D5DD'
            }}
        >
            <Box display='flex' width={36} ml='4px' sx={{ display: eventLevel > 1 ? 'none' : 'inherit' }}>
                <Box display='flex'
                    justifyContent='center'
                    alignItems='center'
                    height='32px'
                    width='32px'
                    sx={{
                        bgcolor: '#F4C647',
                        borderRadius: 2,
                    }}>
                    <Typography>{rank || '-'}</Typography>
                </Box>
            </Box>

            <Box display='flex' width={16} height={12} ml='6px' mb='2px' sx={{ boxShadow: 2, borderRadius: 1, bgcolor: '' }}>
                <FlagComponent countryCode={competitor?.country_code as CountryCode} size='s' />
            </Box>

            <Box display='flex' width={eventLevel > 1 ? 'calc(100% - 438px)' : 'calc(100% - 518px)'} maxWidth='180px' ml='6px' sx={{ bgcolor: '' }}>
                <Typography fontWeight={500} noWrap>{competitorNameString(eventLevel, competitor?.is_relay, competitor?.full_name, competitor?.full_name_reversed)}</Typography>
            </Box>

            <Box display='flex' justifyContent='flex-end' width={40} ml='8px' sx={{ bgcolor: '' }}>
                {event?.event_type && lane?.competitor && determineParaCode(event?.event_type, lane.competitor) && <Typography lineHeight={1} fontSize={'0.7em'} mt={'2px'} mr={'8px'}>{determineParaCode(event?.event_type, lane.competitor)}</Typography>}
                <Typography fontSize='0.9rem' lineHeight={1}>{event?.is_relay ? competitor?.age !== 0 ? competitor?.age : '' : showAge ? competitor?.age !== 0 ? competitor?.age : '' : getBirthYear(competitor?.birthday)}</Typography>
            </Box>

            <Box display='flex' alignItems='center' minWidth='132px' width={eventLevel > 1 ? 'calc(100% - 646px)' : 'calc(100% - 676px)'} ml='8px' sx={{ bgcolor: '' }}>
                <Box component='img' height='32px' width='32px' src={`${process.env.REACT_APP_CDN_URL}club_logos/${lane?.competitor?.club?.country_code?.toLowerCase()}/32/${lane?.competitor?.club?.short_name?.toLowerCase()}.png`} onError={() => setImgLoaded(false)} sx={{ display: imgLoaded ? 'inline' : 'none' }} />
                {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
                {!imgLoaded &&
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        height='32px'
                        minWidth='32px'
                        sx={{ bgcolor: '#374B5C', borderRadius: 2, }}>
                        <BsPeopleFill color='#FFF' size='24px' />
                    </Box>
                }
                <Typography noWrap fontSize='0.9rem' ml='8px' lineHeight={1}>{competitor?.club?.name}</Typography>
            </Box>

            <Box display='flex' alignItems='center' minWidth='132px' pl={2}>
                {competition?.small_image && <Box component='img' height='32px' width='32px' src={competition?.small_image} />}
                {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
                <Box display='flex' flexDirection='column' justifyContent='center' gap={0.25} width={{ xs: 'calc(100% - 24px)', sm: 'calc(100% - 28px)', md: 'calc(100% - 8px)' }} maxWidth={352} sx={{ bgcolor: '' }}>
                    <Typography noWrap lineHeight={1} sx={{ fontWeight: 500, fontSize: { xs: '0.5rem', sm: '0.65rem', md: '0.8rem' } }}>{competition?.pool_name}</Typography>
                    <Box display='flex' alignItems='center' gap={0.5} sx={{ bgcolor: '' }}>
                        <Box width={16} height={12} display='flex' alignItems='center' sx={{ boxShadow: 2, borderRadius: 1, bgcolor: '' }}>
                            <FlagComponent countryCode={competition?.nation_code as CountryCode} size='s' />
                        </Box>
                        <Typography noWrap lineHeight={1} sx={{ fontWeight: 300, fontSize: { xs: '0.5rem', sm: '0.65rem', md: '0.75rem' } }}>{competition?.city}</Typography>
                    </Box>
                </Box>
            </Box>

            <Box display='flex' flexDirection='column' alignItems='end' justifyContent='space-between' width={100} height='28px' ml='20px' sx={{ bgcolor: '' }}>
                <Typography display='flex' justifyContent='flex-end' lineHeight={1} width={72} fontWeight={500} sx={{ bgcolor: '' }}>{lane && summaryTimeDisplay(lane)} </Typography>
                <Box display='flex' justifyContent='flex-end' width={80} sx={{ bgcolor: '' }}>
                    <Typography lineHeight={1} fontWeight={300} fontSize='0.7rem'>{lane && checkLaneStatus(lane)}</Typography>
                </Box>
            </Box>

            <Box display='flex' flexDirection='column' alignItems='start' justifyContent='flex-end' width={60} height='28px' ml='4px' mr='4px' sx={{ bgcolor: '' }}>
                <Typography lineHeight={1} fontWeight={300} fontSize='0.7rem' ml='4px'>{lane?.result_value && lane.entry_time && lane?.result_value !== 0 && !(lane.dnf || lane.dsq || lane.dns) ? entryResultDiff(lane?.entry_time, lane?.result_value) : ''}</Typography>
            </Box>

            <Box height={20}></Box>
        </Grid>
    );
}
