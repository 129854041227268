import React, { useState, useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { DateElement, StatusBadge } from '../../../../../components/layout';
import FlagComponent, { CountryCode } from '../../../../../components/layout/FlagComponent';

export default function CombinedHeaderRow({ competition }: { competition: Competition | undefined; }) {

    const [statusCount, setStatusCount] = useState<number | undefined>();
    const [heatsCount, setHeatsCount] = useState<number | undefined>();
    const [allOfficial, setAllOfficial] = useState<boolean>(false);
    const [roundStatus, setRoundStatus] = useState<number | undefined>();

    useEffect(() => {
        let statusCount: number = 0;
        let heatsCount: number = 0;
        let roundOfficial = true;
        competition?.events && competition?.events[0]?.rounds && competition?.events[0]?.rounds.forEach(round => {
            if (round.status === 5) roundOfficial = false;
        });
        competition?.events && competition?.events[0]?.rounds && competition?.events[0]?.rounds[0]?.heats?.forEach(heat => {
            if (heat.status === 3) statusCount++;
            heatsCount++;
        });
        setAllOfficial(roundOfficial);
        setStatusCount(statusCount);
        setHeatsCount(heatsCount);
        setRoundStatus(competition?.events && competition?.events[0]?.rounds && competition?.events[0]?.rounds[0]?.status);
    }, [competition]);

    //@ts-ignore

    return (
        <Grid container wrap='nowrap' alignItems='center' height={{ xs: 36, md: 44 }} sx={{ width: '100%', borderStyle: 'solid none none none', borderWidth: 1, borderColor: '#D0D5DD', bgcolor: '' }}>
            <Grid container item xs={6} width='100%' sx={{ bgcolor: '', mx: -0.5 }}>

                <Grid item width={56} height={28} my='auto' ml={{ xs: 0.5, sm: 1 }} sx={{ bgcolor: '' }}>
                    <Box component='img' src={competition?.small_image || '/img/img-placeholder-square_32.png'} width={56} height={28} sx={{ borderRadius: 1, boxShadow: 3, objectFit: 'cover' }} />
                </Grid>

                <Grid item gap={{ xs: 0.25, md: 0.5 }} ml={1}
                    width={'calc(100% - 80px)'}
                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', bgcolor: '' }}>
                    <Typography noWrap sx={{ fontWeight: 700, fontSize: { xs: '0.75rem', sm: '0.9rem', md: '1rem' }, lineHeight: 1 }}>{competition?.name}</Typography>
                    <Box display='flex' alignItems='center' gap={{ xs: 0.35, sm: 0.6 }} sx={{ bgcolor: '' }}>
                        {competition?.startDate && competition?.endDate && DateElement(competition?.startDate, competition?.endDate)}
                    </Box>
                </Grid>
            </Grid>
            <Grid container item xs={6} width='100%' sx={{ bgcolor: '' }}>
                <Grid container item display='flex' xs={6} gap={{ xs: 0.5, sm: 0.5 }} my='auto' sx={{ bgcolor: '' }}>
                    <Box height={28} width={28} sx={{ boxShadow: 3, borderRadius: 2 }}>
                        <Box component='img' height={28} width={28} src={competition?.organizer_logo || '/img/img-placeholder-square_32.png'} sx={{ borderRadius: 2 }} />
                    </Box>
                    <Box width={{ xs: 'calc(100% - 36px)', md: 'calc(100% - 40px)' }} maxWidth={340} my='auto' sx={{ bgcolor: '' }}>
                        <Typography noWrap lineHeight={1} sx={{ fontWeight: 300, fontSize: { xs: '0.5rem', sm: '0.65rem', md: '0.75rem' } }}>{competition?.organizer}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} my='auto' sx={{ bgcolor: '' }}>
                    <Box display='flex' justifyContent='flex-start' alignItems='center' gap={{ xs: 0.5, sm: 1 }} sx={{ bgcolor: '' }}>
                        <Box display='flex' flexDirection='column' justifyContent='center' gap={0.25} width={{ xs: 'calc(100% - 24px)', sm: 'calc(100% - 28px)', md: 'calc(100% - 8px)' }} maxWidth={352} sx={{ bgcolor: '' }}>
                            <Typography noWrap lineHeight={1} sx={{ fontWeight: 500, fontSize: { xs: '0.5rem', sm: '0.65rem', md: '0.8rem' } }}>{competition?.pool_name}</Typography>
                            <Box display='flex' alignItems='center' gap={0.5} sx={{ bgcolor: '' }}>
                                <Box width={16} height={12} display='flex' alignItems='center' sx={{ boxShadow: 2, borderRadius: 1, bgcolor: '' }}>
                                    <FlagComponent countryCode={competition?.nation_code as CountryCode} size='s' />
                                </Box>
                                <Typography noWrap lineHeight={1} sx={{ fontWeight: 300, fontSize: { xs: '0.5rem', sm: '0.65rem', md: '0.75rem' } }}>{competition?.city}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box mr={{ xs: 0.5, sm: 1 }} sx={{ py: '5px', bgcolor: '' }}>
                {(statusCount === undefined && heatsCount === undefined)
                    ?
                    <Box display='flex' justifyContent='center' alignItems='center' width={{ xs: 80, sm: 90 }} sx={{ /* background: `linear-gradient(#49647A, #374B5C)`, borderRadius: 1 */ }}>
                        <Typography noWrap display='flex' alignItems='center' justifyContent='center' fontWeight={500} fontSize={{ xs: '0.5rem', sm: '0.65rem', md: '0.8rem' }} color={'#FFF'} px={{ xs: 1, md: 2 }} height={{ xs: '20px', md: '24px' }} width='fit-content' sx={{}}>

                        </Typography>
                    </Box>
                    :
                    roundStatus && roundStatus === 5
                        ?
                        <StatusBadge status={'Official'} width={{ xs: 80, sm: 90 }} />
                        :
                        <Box display='flex' justifyContent='center' alignItems='center' width={{ xs: 80, sm: 90 }} sx={{ background: `linear-gradient(#49647A, #374B5C)`, borderRadius: 1 }}>
                            <Typography noWrap display='flex' alignItems='center' justifyContent='center' fontWeight={500} fontSize={{ xs: '0.5rem', sm: '0.65rem', md: '0.8rem' }} color={'#FFF'} px={{ xs: 1, md: 2 }} height={{ xs: '20px', md: '24px' }} width='fit-content' sx={{}}>
                                {statusCount} / {heatsCount} heats
                            </Typography>
                        </Box>
                }
            </Box>
        </Grid>
    );
}
