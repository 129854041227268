import React from 'react';

import {
    Typography,
    Box,
    AppBar,
    Tabs,
    Tab
} from '@mui/material';

import { styled } from '@mui/material/styles';
import StatusBadge from '../../../components/layout/StatusBadge';

export default function CompetitionSessionTabs({ sessionTabIndex, handleSessionChange, sessions, liveSessionId, currentHeatUpdateThreshold }) {

    const getWeekday = (startTime, locale) => {
        const date = new Date(startTime);
        return date.toLocaleDateString(locale, { weekday: 'short' });
    };

    const getStartTime = (startTime) => {
        const time = new Date(startTime);
        return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const HandleLiveBadge = ({ time }) => {
        const currentTime = Date.now();
        return (
            <>
                {currentTime < currentHeatUpdateThreshold ?
                    <StatusBadge status={"LIVE"} width={32} height={16} fontSize={'0.65rem'} />
                    :
                    <Typography lineHeight={1} fontSize='0.85rem'>{time}</Typography>
                }
            </>
        );
    };

    const tabLabel = (session) => {

        const weekDay = getWeekday(session?.start_time, 'en-EN');
        const time = getStartTime(session?.start_time);

        return (
            <Box display='flex' flexDirection='column' alignItems='flex-start' gap={0.2}>
                <Typography lineHeight={1} fontSize='0.75rem'>Session {session?.number}</Typography>
                <Box display='flex' alignItems='center' gap={0.5}>
                    <Typography lineHeight={1} fontSize='0.85rem'>{weekDay}</Typography>
                    <Typography lineHeight={1} fontSize='0.85rem'>|</Typography>
                    {session?.id === liveSessionId ? <HandleLiveBadge time={time} /> : <Typography lineHeight={1} fontSize='0.85rem'>{time}</Typography>}
                </Box>
            </Box>
        );
    };

    return (
        <AppBar position='static' color='' sx={{ boxShadow: 0, width: '100%', height: '100%', bgcolor: '' }}>
            <Box width='100%' display='flex' justifyContent='center' sx={{ bgcolor: '#D0D5DD' }}>
                <StyledTabs
                    value={sessionTabIndex}
                    onChange={handleSessionChange}
                    variant='scrollable'
                >
                    {sessions?.map((session, index) => (
                        <StyledSmallTab
                            key={session?.id}
                            label={tabLabel(session)}
                            sx={{ bgcolor: index % 2 ? '#E4E7EC' : '#F2F4F7' }}
                        />
                    ))}
                </StyledTabs>
            </Box>
        </AppBar>
    );
}

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className='MuiTabs-indicatorSpan'
            />
        }}
    />
))(
    ({ theme }) => ({
        minHeight: 0,
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            bottom: 1,
        },
        '& .MuiTabs-indicatorSpan': {
            maxWidth: 50,
            marginTop: 0,
            paddingTop: 0,
            paddingBottom: 0,
            [theme.breakpoints.up('sm')]: {
                maxWidth: 60,
            },
            width: '100%',
            backgroundColor: '#FFF',
        },
    })
);

const StyledSmallTab = styled((props) => (
    <Tab
        {...props}
        sx={{
            color: '#333',
            '&:hover': {
                color: props.hovercolor ?? '#000',
                opacity: 1,
            },
            '&.Mui-selected': {
                color: props.selectedcolor ?? '#FFF',
                transition: 'all 0.3s ease',
                backgroundColor: '#374B5C',
            },
            '&.Mui-focusVisible': {
                backgroundColor: props.focuscolor ?? '#D0D5DD',
            },
        }}
    />
))(
    ({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        minHeight: 0,
        height: '2.4rem',
        [theme.breakpoints.up('md')]: {
            maxWidth: 130,
        },
    }),
);