import React from "react";

import { Box, Typography, Grid } from "@mui/material";

import statusGradientColor from "../../../../components/utils/statusGradientColor";
import { TimeProgramEntryIcon } from "../../../../components/layout";
import { HandleEventStatus, openInNewTab } from "../../../../components/utils";
import RecordBox from "../../../../components/layout/RecordBox";

import { GET_EVENT_RECORD } from "../../../../utils/graphql/queries";

import useFetch from "../../../../hooks/graphql/useFetch";

export default function EventInfo({ liveHeat }: { liveHeat?: Heat }) {
  const liveTpe = liveHeat?.time_program_entry;
  const sponsorImg = liveTpe?.round?.event?.sponsor?.img;
  const sponsorLink = liveTpe?.round?.event?.sponsor?.link;
  const sponsorText = liveTpe?.round?.event?.sponsor?.name;
  const eventId = liveTpe?.round?.event?.id;

  const handleSponsorClick = (link: string | undefined) => {
    link && openInNewTab(link);
  };

  const {
    data: eventRecords,
    loading,
    error,
  } = useFetch(GET_EVENT_RECORD, { id: eventId }, "id");

  return (
    <Grid
      container
      item
      justifyContent="space-between"
      id="live-event-info"
      py={1}
      mb={1}
      sx={{ bgcolor: "" }}
    >
      {/* TODO: if no sponsorImg or sponsorText, make tpe-name full width */}
      <Grid
        container
        item
        width={sponsorImg || sponsorText ? "calc(100% - 224px)" : "100%"}
        sx={{ bgcolor: "" }}
      >
        <Grid
          container
          item
          my="auto"
          width={
            sponsorImg || sponsorText
              ? "calc(100% - 98px)"
              : "calc(100% - 98px)"
          }
          justifyContent={{ xs: "center", md: "flex-start" }}
          alignItems="center"
          sx={{ bgcolor: "" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="32px"
            minWidth="32px"
            ml={1}
            sx={{
              borderRadius: 2,
              background: statusGradientColor(
                liveTpe?.id,
                liveTpe?.id,
                liveTpe?.round?.status
              ),
            }}
          >
            <Typography
              color="#FFF"
              fontSize="1rem"
              lineHeight={1}
              sx={{ mb: -0.25 }}
            >
              <TimeProgramEntryIcon entry={liveTpe} />
            </Typography>
          </Box>
          <Typography
            noWrap
            width="calc(100% - 56px)"
            mx={1}
            mb={-0.25}
            fontWeight={600}
            fontSize={{ xs: "1rem", sm: "1.2rem", md: "1.8rem" }}
          >
            {liveTpe?.name}
          </Typography>
        </Grid>
        <Grid
          item
          width="90px"
          mb={-0.25}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mr={1}
          sx={{ bgcolor: "" }}
        >
          {liveTpe?.round?.status && HandleEventStatus(liveTpe?.round?.status)}
        </Grid>
      </Grid>
      <Box width="80%" pl={"1em"} pt={"1em"} pr={"1em"} position={"relative"}>
        {!loading && eventRecords && eventRecords.event_records.length > 0 && (
          <RecordBox eventRecords={eventRecords.event_records} />
        )}
      </Box>
      <Grid
        item
        maxWidth={224}
        display="flex"
        alignItems="center"
        justifyContent={{ xs: "center", md: "flex-end" }}
        sx={{ bgcolor: "" }}
      >
        {sponsorImg && (
          <Box
            width="auto"
            maxWidth={224}
            maxHeight={120}
            mx={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ bgcolor: "" }}
          >
            <Box
              component="img"
              width="auto"
              maxWidth={216}
              maxHeight={120}
              src={sponsorImg}
              onClick={() => handleSponsorClick(sponsorLink)}
              sx={{
                borderRadius: 1,
                boxShadow: 3,
                cursor: sponsorLink && "pointer",
              }}
            />
          </Box>
        )}
        {!sponsorImg && sponsorText && (
          <Box
            maxWidth={224}
            maxHeight={120}
            display="flex"
            flexDirection="column"
            alignItems="center"
            mx={1}
            onClick={() => handleSponsorClick(sponsorLink)}
            sx={{
              /* borderRadius: 1, boxShadow: 3, */ cursor:
                sponsorLink && "pointer",
              bgcolor: "",
            }}
          >
            <Typography
              mx={2}
              fontWeight={300}
              fontSize={{ xs: "0.6rem", sm: "0.8rem", md: "1rem" }}
              sx={{ bgcolor: "" }}
            >
              Sponsor
            </Typography>
            <Typography
              mx={2}
              fontWeight={500}
              fontSize={{ xs: "0.6rem", sm: "0.8rem", md: "1rem" }}
              sx={{ bgcolor: "" }}
            >
              {sponsorText}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
