import { BsCloudDownload } from "react-icons/bs";
import { Box, Typography, IconButton } from "@mui/material";
import { theme } from "../../utils/constants/mui-theme";

function AndroidAppBanner() {
  const redirectToPlayStore = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.app.livetiming";
  };

  return (
    <Box
      component="div"
      sx={{
        bgcolor: "black",
        color: "white",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingX: 2,
        paddingY: 2,
      }}
      onClick={redirectToPlayStore}
    >
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <IconButton>
          <Box
            component="img"
            sx={{
              height: 80,
              width: 80,

              borderRadius: 4,
            }}
            src="/logo/swimify-livetiming-logo.png"
            alt="Swimify Logo"
          />
        </IconButton>
        <Box component="div" sx={{ paddingLeft: 1 }}>
          <Typography variant="h4" sx={{ fontWeight: 500 }}>
            Swimify Livetiming
          </Typography>
          <Typography variant="body1" color="lightgray">
            Download the app here
          </Typography>
        </Box>
      </Box>
      <IconButton sx={{ color: "white" }}>
        <BsCloudDownload
          color={theme.palette.lightBlue.main}
          fontWeight="900"
          size={42}
        />
      </IconButton>
    </Box>
  );
}

export default AndroidAppBanner;
