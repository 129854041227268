import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Typography, Box, Grid } from "@mui/material";

import {
  getEventLevel,
  heatRankDisplay,
  entryResultDiff,
  heatTimeDisplay,
  heatLaneEntryTime,
} from "../../../../../../components/utils";
import SubResults from "../../../../event/components/SubResults";
import RelayHeatSubResults from "../../../../event/components/heats/RelayHeatSubResults";
import { determineParaCode } from "../../../../../../components/utils/determineParaCodes";

export default function ClubViewHeatCompetitorLane({
  lane,
  index,
  event,
  heatStatus,
  showAge,
}) {
  const initialRender = useRef(true);

  useEffect(() => {
    initialRender.current = false;
  }, []);

  const [imgLoaded, setImgLoaded] = useState(true);
  const [eventLevel, setEventLevel] = useState(0);

  useEffect(() => {
    if (event) {
      setEventLevel(getEventLevel(event?.event_competition_level));
    }
  }, [event]);

  return (
    <Grid
      container
      item
      xs={12}
      py={0.5}
      alignItems="center"
      /* onClick={() => {
                navigate(competitorNavigationString(params.competitionName, lane?.competitor?.club?.oid, lane?.competitor?.oid));
                navigate(0);
            }} */
      sx={{
        bgcolor: "#FFF",
        //cursor: 'pointer',
        borderTopStyle: index !== 0 && "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
      }}
    >
      <Box display="flex" width={36} ml="4px" sx={{ bgcolor: "" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="32px"
          width="32px"
          sx={{
            bgcolor: "#F4C647",
            borderRadius: 2,
          }}
        >
          <Typography>{event?.number}</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        width="calc(100% - 518px)"
        maxWidth="200px"
        ml="6px"
        sx={{ bgcolor: "" }}
      >
        <Typography fontWeight={500} noWrap>
          {event?.name}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        minWidth="122px"
        width="calc(100% - 637px)"
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        <Box
          component="img"
          height="32px"
          width="32px"
          src={`${
            process.env.REACT_APP_CDN_URL
          }club_logos/${lane?.competitor?.club?.country_code.toLowerCase()}/32/${lane?.competitor?.club?.short_name.toLowerCase()}.png`}
          onError={() => setImgLoaded(false)}
          sx={{ display: imgLoaded ? "inline" : "none" }}
        />
        {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
        {!imgLoaded && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="32px"
            minWidth="32px"
            sx={{ bgcolor: "#E4E7EC", borderRadius: 2 }}
          >
            <Typography>{lane?.heat?.number}</Typography>
          </Box>
        )}
        <Typography noWrap fontSize="0.9rem" ml="8px" lineHeight={1}>
          {lane?.heat?.name}
        </Typography>
      </Box>
      <Box display="flex" width={36} ml="4px">
        {event?.event_type &&
          lane?.competitor &&
          determineParaCode(event.event_type, lane.competitor) && (
            <Typography lineHeight={1} fontSize={"0.7em"} mt={"2px"} mr={"8px"}>
              {determineParaCode(event.event_type, lane.competitor)}
            </Typography>
          )}
      </Box>
      <Box display="flex" width={36} ml="4px" sx={{ bgcolor: "" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="32px"
          width="32px"
          sx={{
            bgcolor: "#374B5C",
            borderRadius: 2,
          }}
        >
          <Typography color="#FFF">{lane?.number}</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={28}
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        <Typography fontSize="0.7rem">{lane?.reaction_time}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={32}
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        <Typography
          fontSize="0.7rem"
          sx={{ display: eventLevel > 1 ? "none" : "inherit" }}
        >
          {lane?.fina_points !== 0 && lane?.fina_points}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        width={36}
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        {heatRankDisplay(heatStatus, lane) && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="24px"
            width="24px"
            sx={{
              bgcolor: "#374B5C",
              borderRadius: 1,
              display: eventLevel > 1 ? "none" : "inherit",
            }}
          >
            <Typography color="#FFF">
              {heatRankDisplay(heatStatus, lane)}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="end"
        justifyContent="flex-end"
        width={80}
        height="28px"
        ml="4px"
        sx={{ bgcolor: "" }}
      >
        <Typography
          display="flex"
          justifyContent="flex-end"
          lineHeight={1}
          width={72}
          fontWeight={500}
          sx={{ bgcolor: "" }}
        >
          {heatTimeDisplay(heatStatus, lane)}
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={80}
          sx={{ bgcolor: "" }}
        >
          <Typography lineHeight={1} fontWeight={300} fontSize="0.7rem">
            {heatLaneEntryTime(lane)}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="flex-end"
        width={60}
        height="28px"
        ml="4px"
        sx={{ bgcolor: "" }}
      >
        <Typography
          noWrap
          width="auto"
          ml="4px"
          maxWidth={48}
          lineHeight={1}
          fontSize="0.8rem"
        >
          {lane?.note}
        </Typography>
        <Typography lineHeight={1} fontWeight={300} fontSize="0.7rem" ml="4px">
          {lane?.result_value !== 0 &&
            entryResultDiff(lane?.entry_time, lane?.result_value)}
        </Typography>
      </Box>
      <Box
        display="flex"
        width={18}
        height="28px"
        alignItems="start"
        ml="8px"
        mr="8px"
        sx={{ bgcolor: "" }}
      >
        <Typography lineHeight={1} fontSize="0.8rem">
          {lane?.qualification}
        </Typography>
      </Box>
      {event?.is_relay && (
        <RelayHeatSubResults lane={lane} event={event} showAge={showAge} />
      )}
      {!event?.is_relay &&
        lane?.sub_results[0]?.split_diff_text !== "" &&
        event?.distance > 50 && (
          <SubResults
            lane={lane}
            distance={event?.distance}
            initialRender={initialRender.current}
          />
        )}
    </Grid>
  );
}
