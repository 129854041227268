import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Typography,
    Box,
    Grid,
} from '@mui/material';

import { BsPeopleFill } from 'react-icons/bs';

import { FlagComponent } from '../../../../../components/layout';
import { competitorNameString, competitorNavigationString, getBirthYear } from '../../../../../components/utils';

export default function CompetitionQualifiersCard({ entry, index, eventLevel, showAge }) {

    const navigate = useNavigate();
    const params = useParams();

    const [imgLoaded, setImgLoaded] = useState(true);

    const competitor = entry?.lane?.competitor;

    return (
        <>
            <Grid container item xs={12} py={0.5} mx={0} alignItems='center'
                onClick={() => {
                    navigate(competitorNavigationString(params.competitionName, competitor?.club?.oid, competitor?.oid));
                    navigate(0);
                }}
                sx={{
                    bgcolor: '#FFF',
                    cursor: 'pointer',
                    borderTopStyle: index !== 0 && 'solid',
                    borderWidth: 1,
                    borderColor: '#D0D5DD'
                }}>
                <Box display='flex' width={36} ml='4px' sx={{ bgcolor: '', display: eventLevel > 1 ? 'none' : 'inherit' }}>
                    <Box display='flex'
                        justifyContent='center'
                        alignItems='center'
                        height='32px'
                        width='32px'
                        sx={{
                            bgcolor: '#F4C647',
                            borderRadius: 2,
                        }}>
                        <Typography>{entry?.rank}</Typography>
                    </Box>
                </Box>
                <Box display='flex' width={16} height={12} ml='6px' mb='2px' sx={{ boxShadow: 2, borderRadius: 1, bgcolor: '' }}>
                    <FlagComponent countryCode={competitor?.country_code} size='s' />
                </Box>
                <Box display='flex' width='calc(100% - 374px)' maxWidth='240px' ml='6px' sx={{ bgcolor: '' }}>
                    <Typography fontWeight={500} noWrap>{competitorNameString(eventLevel, competitor?.is_relay, competitor?.full_name, competitor?.full_name_reversed)}</Typography>
                </Box>
                <Box display='flex' justifyContent='flex-end' width={40} ml='8px' sx={{ bgcolor: '' }}>
                    <Typography fontSize='0.9rem' lineHeight={1}>{showAge ? competitor?.age : getBirthYear(competitor?.birthday)}</Typography>
                </Box>
                <Box display='flex' alignItems='center' minWidth='134px' width={eventLevel > 1 ? 'calc(100% - 440px)' : 'calc(100% - 480px)'} ml='8px' sx={{ bgcolor: '' }}>
                    <Box component='img' height='32px' width='32px' src={`${process.env.REACT_APP_CDN_URL}club_logos/${competitor?.club?.country_code.toLowerCase()}/32/${competitor?.club?.short_name.toLowerCase()}.png`} onError={() => setImgLoaded(false)} sx={{ display: imgLoaded ? 'inline' : 'none' }} />
                    {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
                    {!imgLoaded &&
                        <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            height='32px'
                            minWidth='32px'
                            sx={{ bgcolor: '#374B5C', borderRadius: 2, }}>
                            <BsPeopleFill color='#FFF' size='24px' />
                        </Box>
                    }
                    <Typography noWrap fontSize='0.9rem' ml='8px' lineHeight={1}>{competitor?.club?.name}</Typography>
                </Box>
                <Box display='flex' justifyContent='flex-end' width={24} ml='8px' sx={{ bgcolor: '' }}>
                    <Typography>{entry?.lane?.qualification}</Typography>
                </Box>
                <Box display='flex' justifyContent='flex-end' width={72} ml='8px' mr='4px' sx={{ bgcolor: '' }}>
                    <Typography fontWeight={500}>{entry?.lane?.result_text}</Typography>
                </Box>
            </Grid>
        </>
    );
}
