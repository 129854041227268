import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import { BsPeopleFill } from "react-icons/bs";
import {
  EntriesTimeDisplay,
  FlagComponent,
} from "../../../../../../components/layout";
import {
  competitorNavigationString,
  competitorNameString,
  getBirthYear,
} from "../../../../../../components/utils";
import { determineParaCode } from "../../../../../../components/utils/determineParaCodes";

export default function ClubViewEventListEntries({
  entry,
  index,
  setSelectedCompetitor,
  showAge,
  eventLevel,
  setView,
  eventType,
}) {
  const params = useParams();
  const navigate = useNavigate();

  const [imgLoaded, setImgLoaded] = useState(true);

  const competitor = entry?.competitor;

  return (
    <Grid
      container
      item
      xs={12}
      py={0.5}
      alignItems="center"
      onClick={() => {
        setSelectedCompetitor(entry?.competitor);
        navigate(
          competitorNavigationString(
            params.competitionName,
            competitor?.club?.oid,
            competitor?.oid
          )
        );
        setView(3);
      }}
      sx={{
        bgcolor: "#FFF",
        cursor: "pointer",
        borderTopStyle: index !== 0 && "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
      }}
    >
      <Box
        display="flex"
        width={36}
        ml="4px"
        sx={{ bgcolor: "", display: eventLevel > 1 ? "none" : "inherit" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="32px"
          width="32px"
          sx={{
            bgcolor: entry?.reserve ? "#E4E7EC" : "#F4C647",
            borderRadius: 2,
          }}
        >
          <Typography>{entry.reserve ? "R" : entry?.ranking}</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        width={16}
        height={12}
        ml="6px"
        mb="2px"
        sx={{ boxShadow: 2, borderRadius: 1, bgcolor: "" }}
      >
        <FlagComponent countryCode={competitor?.country_code} size="s" />
      </Box>
      <Box
        display="flex"
        width={eventLevel > 1 ? "calc(100% - 404px)" : "calc(100% - 444px)"}
        maxWidth="240px"
        ml="6px"
        sx={{ bgcolor: "" }}
      >
        <Typography fontWeight={entry?.reserve ? 300 : 500} noWrap>
          {competitorNameString(
            eventLevel,
            competitor?.is_relay,
            competitor?.full_name,
            competitor?.full_name_reversed
          )}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={40}
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        {eventType &&
          entry?.competitor &&
          determineParaCode(eventType, entry.competitor) && (
            <Typography lineHeight={1} fontSize={"0.7em"} mt={"2px"} mr={"8px"}>
              {determineParaCode(eventType, entry.competitor)}
            </Typography>
          )}
        <Typography fontSize="0.9rem" lineHeight={1}>
          {showAge ? competitor?.age : getBirthYear(competitor?.birthday)}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        minWidth="156px"
        width={eventLevel > 1 ? "calc(100% - 488px)" : "calc(100% - 528px)"}
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        <Box
          component="img"
          height="32px"
          width="32px"
          src={`${
            process.env.REACT_APP_CDN_URL
          }club_logos/${competitor?.club?.country_code.toLowerCase()}/32/${competitor?.club?.short_name.toLowerCase()}.png`}
          onError={() => setImgLoaded(false)}
          sx={{ display: imgLoaded ? "inline" : "none" }}
        />
        {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
        {!imgLoaded && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="32px"
            width="32px"
            sx={{ bgcolor: "#374B5C", borderRadius: 2 }}
          >
            <BsPeopleFill color="#FFF" size="28px" />
          </Box>
        )}
        <Typography noWrap fontSize="0.9rem" ml="8px" lineHeight={1}>
          {competitor?.club?.name}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width={148}
        ml="8px"
        mr="8px"
        sx={{ bgcolor: "" }}
      >
        <EntriesTimeDisplay entry={entry} />
      </Box>
    </Grid>
  );
}
