import React, { useState, useContext, useRef, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Typography, Box, Grid } from "@mui/material";

import { BsPeopleFill } from "react-icons/bs";

import SubResults from "../SubResults";
import RelaySummarySubResults from "./RelaySummarySubResults";

import {
  competitorNameString,
  competitorNavigationString,
  entryResultDiff,
  getBirthYear,
  summaryTimeDisplay,
} from "../../../../../components/utils";
import { FlagComponent } from "../../../../../components/layout";
import { CountryCode } from "../../../../../components/layout/FlagComponent";
import { ScreenContext } from "../../../../../context/ScreenContext";
import { determineParaCode } from "../../../../../components/utils/determineParaCodes";
import { cropIfNessesary } from "../../../../../components/utils/cropIfNessesary";

export interface SummaryHideCondition {
  name: string | undefined;
  hide_q: boolean | undefined;
  sort_by_name: boolean | undefined;
}

export default function SummaryCard({
  round,
  rank,
  lane,
  competitor,
  index,
  event,
  eventLevel,
  setView,
  setSelectedCompetitor,
  showAge,
  summaryHideCondition,
}: {
  round?: number;
  rank?: number;
  lane?: Lane;
  competitor?: Competitor;
  index: number;
  event?: Event;
  eventLevel: number;
  setView?: React.Dispatch<React.SetStateAction<number>>;
  setSelectedCompetitor?: React.Dispatch<
    React.SetStateAction<Competitor | undefined>
  >;
  showAge: boolean | undefined;
  summaryHideCondition: SummaryHideCondition;
}) {
  const { setScreen } = useContext(ScreenContext);
  const navigate = useNavigate();
  const params = useParams();

  const initialRender = useRef(true);

  const [result, setResult] = useState<Lane | undefined>();
  const [animateResult, setAnimateResult] = useState<number>(0);

  const handlePodiumColor = (rank: number): string[] => {
    switch (rank) {
      case 1:
        return ["rgba(245, 209, 51, 1)", "rgba(235, 195, 22, 1)"];
      case 2:
        return ["rgba(227, 227, 227, 1)", "rgba(211, 211, 211, 1)"];
      case 3:
        return ["rgba(245, 169, 108, 1)", "#BF6F3D"];
      default:
        return ["#F4C647", "#F4C647"];
    }
  };

  useEffect(() => {
    initialRender.current = false;
  }, []);

  useMemo(() => {
    if (lane?.result_text && initialRender.current === false) {
      setAnimateResult(1);
    }
  }, [result]);

  useMemo(() => {
    if (lane?.result_text != result?.result_text) {
      setResult(lane);
    }
  }, [lane]);

  const [imgLoaded, setImgLoaded] = useState<boolean>(true);

  const isPara = event?.is_para_event;
  const paraPoints = lane?.wps_points;

  const checkLaneStatus = (lane: Lane) => {
    if (lane?.dns || lane?.dnf || lane?.dsq || !lane?.entry_time_text)
      return null;
    return `Entry: ${lane?.entry_time_text}`;
  };

  const laneNote = `${lane?.note}${lane?.note && (lane?.not_in_competition || competitor?.not_in_competition)
    ? ", "
    : ""
    }${lane?.not_in_competition || competitor?.not_in_competition ? "EXH" : ""}`;

  return (
    <>
      <Grid
        container
        item
        display="flex"
        xs={12}
        alignItems="center"
        mt={0}
        py={0.5}
        onClick={() => {
          navigate(
            competitorNavigationString(
              params?.competitionName,
              competitor?.club?.oid,
              competitor?.oid
            )
          );
          /* check if in eventView or competitorView */
          if (params.clubView) {
            setView && setView(3);
            setSelectedCompetitor && setSelectedCompetitor(competitor);
          } else setScreen("swimmers");
        }}
        //@ts-ignore
        sx={{
          bgcolor:
            eventLevel <= 1 && (round === 0 || round === 2)
              ? rank === 1
                ? "rgba(235, 195, 22, 1)"
                : rank === 2
                  ? "rgba(211, 211, 211, 1)"
                  : rank === 3
                    ? "#BF6F3D"
                    : "#FFF"
              : "#FFF",
          cursor: "pointer",
          borderTopStyle: index !== 0 && "solid",
          borderWidth: 1,
          borderColor: "#D0D5DD",
        }}
      >
        <Box
          display="flex"
          width={36}
          ml="4px"
          sx={{ display: eventLevel > 1 ? "none" : "inherit" }}
        >
          {(!summaryHideCondition || !summaryHideCondition?.sort_by_name) && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="32px"
              width="32px"
              //@ts-ignore
              sx={{
                bgcolor: "#F4C647",
                borderRadius: 2,
                marginTop: 0.5,
                marginBottom: 0.5,
                boxShadow: 1,
                background:
                  round === 2 || round === 0
                    ? rank && `linear-gradient(${handlePodiumColor(rank)})`
                    : "",
              }}
            >
              <Typography>{rank === 0 ? "-" : rank}</Typography>
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          width={16}
          height={12}
          ml="6px"
          mb="2px"
          sx={{ boxShadow: 2, borderRadius: 1, bgcolor: "" }}
        >
          <FlagComponent
            countryCode={competitor?.country_code as CountryCode}
            size="s"
          />
        </Box>
        <Box
          display="flex"
          width={eventLevel > 1 ? "calc(100% - 438px)" : "calc(100% - 518px)"}
          maxWidth="240px"
          ml="6px"
          sx={{ bgcolor: "" }}
        >
          <Typography fontWeight={500} noWrap>
            {competitorNameString(
              eventLevel,
              competitor?.is_relay,
              competitor?.full_name,
              competitor?.full_name_reversed
            )}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={40}
          ml="8px"
          sx={{ bgcolor: "" }}
        >
          {event?.event_type &&
            lane?.competitor &&
            determineParaCode(event?.event_type, lane.competitor) && (
              <Typography
                lineHeight={1}
                fontSize={"0.7em"}
                mt={"2px"}
                mr={"8px"}
              >
                {determineParaCode(event?.event_type, lane.competitor)}
              </Typography>
            )}
          <Typography fontSize="0.9rem" lineHeight={1}>
            {event?.is_relay
              ? competitor?.age !== 0
                ? competitor?.age
                : ""
              : showAge
                ? competitor?.age !== 0
                  ? competitor?.age
                  : ""
                : getBirthYear(competitor?.birthday)}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          minWidth="132px"
          width={eventLevel > 1 ? "calc(100% - 546px)" : "calc(100% - 626px)"}
          ml="8px"
          sx={{ bgcolor: "" }}
        >
          <Box
            component="img"
            height="32px"
            width="32px"
            src={`${process.env.REACT_APP_CDN_URL
              }club_logos/${lane?.competitor?.club?.country_code?.toLowerCase()}/32/${lane?.competitor?.club?.short_name?.toLowerCase()}.png`}
            onError={() => setImgLoaded(false)}
            sx={{ display: imgLoaded ? "inline" : "none" }}
          />
          {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
          {!imgLoaded && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="32px"
              minWidth="32px"
              sx={{ bgcolor: "#374B5C", borderRadius: 2 }}
            >
              <BsPeopleFill color="#FFF" size="24px" />
            </Box>
          )}
          <Typography noWrap fontSize="0.9rem" ml="8px" lineHeight={"normal"}>
            {competitor?.club?.name}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={28}
          ml="8px"
          sx={{ bgcolor: "" }}
        >
          <Typography fontSize="0.7rem">{lane?.reaction_time}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={32}
          ml="8px"
          sx={{ bgcolor: "", display: eventLevel > 1 ? "none" : "inherit" }}
        >
          <Typography fontSize="0.7rem">
            {!isPara
              ? lane?.fina_points !== 0 && lane?.fina_points
              : paraPoints !== 0 && paraPoints}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="end"
          justifyContent="space-between"
          width={80}
          height="28px"
          ml="4px"
          sx={{ bgcolor: "" }}
        >
          <Typography
            display="flex"
            justifyContent="flex-end"
            lineHeight={1}
            width={72}
            fontWeight={500}
            sx={{
              bgcolor: "",
              width: "fit-content",
              borderRadius: "4px",
              paddingLeft: "4px",
              paddingRight: "4px",
              paddingTop: "1px",
              paddingBottom: "1px",
            }}
            onAnimationEnd={() => setAnimateResult(0)}
            data-animationon={animateResult}
            className="heat-result"
          >
            {lane && result && summaryTimeDisplay(result)}{" "}
          </Typography>
          <Box
            display="flex"
            justifyContent="flex-end"
            width={80}
            sx={{ bgcolor: "" }}
          >
            <Typography lineHeight={1} fontWeight={300} fontSize="0.7rem">
              {lane && checkLaneStatus(lane)}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="flex-end"
          width={60}
          height="28px"
          ml="4px"
          mr="4px"
          sx={{ bgcolor: "" }}
        >
          <Box
            sx={{
              whiteSpace: "nowrap",
              width: "auto",
              ml: "4px",
              maxWidth: 48,
              lineHeight: 1,
              fontSize: "0.7rem",
              display: "flex",
              alignItems: "center",
              position: "relative",
              cursor: "help",
              "&:hover": { "& .hover_box": { opacity: 1, zIndex: 100 } },
            }}
          >
            <Box
              className={"hover_box"}
              sx={{
                bgcolor: "white",
                position: "absolute",
                top: "-1.9em",
                left: "-.3em",
                minWidth: "6em",
                bgColor: "white",
                padding: "3px",
                border: "gray 1px solid",
                opacity: 0,
                pointerEvents: "none",
                borderRadius: "4px",
                transition: "opacity .1s ease-in-out",
              }}
            >
              {laneNote}
            </Box>
            {cropIfNessesary(laneNote, 13)}
          </Box>
          <Typography
            lineHeight={1}
            fontWeight={300}
            fontSize="0.7rem"
            ml="4px"
          >
            {lane?.result_value &&
              lane.entry_time &&
              lane?.result_value !== 0 &&
              !(lane.dnf || lane.dsq || lane.dns)
              ? entryResultDiff(lane?.entry_time, lane?.result_value)
              : ""}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width={18}
          height="28px"
          ml="8px"
          mr="8px"
          sx={{ bgcolor: "" }}
        >
          <Typography lineHeight={1} fontSize="0.8rem">
            {(!summaryHideCondition || !summaryHideCondition?.hide_q) &&
              lane?.qualification}
          </Typography>
        </Box>
        {event?.is_relay ? (
          <RelaySummarySubResults
            lane={lane}
            event={event}
            showAge={showAge}
            rank={rank}
            round={round}
            eventLevel={eventLevel}
            initialRender={initialRender.current}
          />
        ) : (
          lane?.sub_results &&
          event?.distance &&
          lane?.sub_results[0]?.split_diff_text !== "" &&
          event?.distance > 50 && (
            <SubResults
              rank={rank}
              round={round}
              lane={lane}
              distance={event?.distance}
              eventLevel={eventLevel}
              initialRender={initialRender.current}
            />
          )
        )}
      </Grid>
    </>
  );
}
