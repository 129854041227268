import React from "react";

import { Box } from "@mui/system";

export default function EntryResultDiff2({ lane }: { lane: Lane }) {
  const { dns, dnf, dsq } = lane;
  const diff = lane.result_value && lane.entry_time && lane?.result_value - lane?.entry_time;

  //console.log("Lane:" + lane?.number, diff && diff/1000)
  return (
    <>
      {dns || dnf || dsq || diff === 0 || !diff
        ? null
        : diff && diff < 0
        ? (
            <Box color={"green"}>
                {(diff/1000).toFixed(2)}
            </Box>
          )
        : diff && (
            <Box color={"red"}>
              + {(diff/1000).toFixed(2)}
            </Box>
          )}
    </>
  );
}
