import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { BsPeopleFill } from "react-icons/bs";
import { EntriesTimeDisplay } from "../../../../../../components/layout";
import { determineParaCode } from "../../../../../../components/utils/determineParaCodes";

export default function ClubViewEntriesListCard({ entry, competitor, index }) {
  const params = useParams();
  const navigate = useNavigate();

  const [imgLoaded, setImgLoaded] = useState(true);

  return (
    <Grid
      container
      item
      xs={12}
      py={0.5}
      alignItems="center"
      onClick={() => {
        navigate(
          `../competitions/${params?.competitionName}/events/entries/${entry?.event?.rounds[0]?.time_program_entries[0]?.competition_session?.oid}/${entry?.event?.rounds[0]?.time_program_entries[0]?.oid}`
        );
      }}
      sx={{
        bgcolor: "#FFF",
        cursor: "pointer",
        borderTopStyle: index !== 0 && "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
      }}
    >
      <Box display="flex" width={36} ml="4px" sx={{ bgcolor: "" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="32px"
          width="32px"
          sx={{
            bgcolor: entry?.reserve ? "#E4E7EC" : "#F4C647",
            borderRadius: 2,
          }}
        >
          <Typography>{entry?.event?.number}</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        width="calc(100% - 404px)"
        maxWidth="240px"
        ml="6px"
        sx={{ bgcolor: "" }}
      >
        <Typography noWrap fontWeight={entry?.reserve ? 300 : 500}>
          {entry?.event?.name}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        minWidth="186px"
        width="calc(100% - 458px)"
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        <Box
          component="img"
          height="32px"
          width="32px"
          src={`${
            process.env.REACT_APP_CDN_URL
          }club_logos/${competitor?.club?.country_code.toLowerCase()}/32/${competitor?.club?.short_name.toLowerCase()}.png`}
          onError={() => setImgLoaded(false)}
          sx={{ display: imgLoaded ? "inline" : "none" }}
        />
        {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
        {!imgLoaded && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="32px"
            width="32px"
            sx={{ bgcolor: "#374B5C", borderRadius: 2 }}
          >
            <BsPeopleFill color="#FFF" size="28px" />
          </Box>
        )}
        <Typography noWrap fontSize="0.9rem" ml="8px" lineHeight={1}>
          {competitor?.club?.name}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width={148}
        ml="8px"
        mr="8px"
        sx={{ bgcolor: "" }}
      >
        {entry?.event?.event_type &&
          competitor &&
          determineParaCode(entry?.event?.event_type, competitor) && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              mt={"2px"}
              mr={"8px"}
            >
              <Typography lineHeight={1} fontSize={"0.7em"}>
                {determineParaCode(entry?.event?.event_type, competitor)}
              </Typography>
            </Box>
          )}
        <Box display="flex" flex="0.9" justifyContent="flex-end">
          <EntriesTimeDisplay entry={entry} />
        </Box>
      </Box>
    </Grid>
  );
}
