import React, { useState, useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import { Typography, Box, Grid, Divider } from "@mui/material";

import { AUClient, EUClient } from "../../../utils/apollo/apollo-client";
import {
  GET_FILTERED_UPCOMING_COMPETITIONS,
  GET_UPCOMING_COMPETITIONS,
} from "../../../utils/graphql/queries";
import CompetitionCard from "../../../components/containers/CompetitionCard";
import { NoCompetitionsLink } from "../../../components/layout";
import QueryResult from "../../../components/containers/QueryResult";

export default function UpcomingCompetitions({
  selectedRegion,
  selectedCountry,
}) {
  const [euCompetitions, setEuCompetitions] = useState([]);
  const [auCompetitions, setAuCompetitions] = useState([]);
  const [combinedCompetitions, setCombinedCompetitions] = useState([]);
  const [filteredCompetitions, setFilteredCompetitions] = useState([]);

  const addDays = () => {
    const higherDate = new Date(
      Date.now() + 3 * 24 * 60 * 60 * 1000
    ).toLocaleDateString("SV-se");
    return higherDate;
  };

  const handleHeader = () => {
    if (selectedRegion === 1) {
      if (combinedCompetitions.length < 1) {
        return "No Upcoming Competitions";
      } else return "Upcoming Competitions";
    } else {
      if (filteredCompetitions.length < 1) {
        return "No Upcoming Competitions";
      } else return "Upcoming Competitions";
    }
  };

  // fetch competitions with startDate greater than currentDate
  const [euCompetitionFetch, { loading: euLoading }] = useLazyQuery(
    GET_UPCOMING_COMPETITIONS,
    {
      client: EUClient,
      fetchPolicy: "network-only",
      variables: { _gt: addDays() },
      onCompleted: (data) => {
        const euComps = data?.competitions.map((competition) => ({
          ...competition,
          database_region: 2,
        }));
        setEuCompetitions(euComps);
      },
    }
  );

  const [auCompetitionFetch, { loading: auLoading }] = useLazyQuery(
    GET_UPCOMING_COMPETITIONS,
    {
      client: AUClient,
      fetchPolicy: "network-only",
      variables: { _gt: addDays() },
      onCompleted: (data) => {
        const auComps = data?.competitions.map((competition) => ({
          ...competition,
          database_region: 3,
        }));
        setAuCompetitions(auComps);
      },
    }
  );

  const [filteredFetch, { loading: filteredLoading, data: filteredData }] =
    useLazyQuery(GET_FILTERED_UPCOMING_COMPETITIONS, {
      client: selectedRegion === 2 ? EUClient : AUClient,
      fetchPolicy: "network-only",
      variables: {
        _gt: addDays(),
        nation: selectedCountry,
      },
      onCompleted: (data) => {
        const filteredComps = data?.competitions.map((competition) => ({
          ...competition,
          database_region: selectedRegion,
        }));
        setFilteredCompetitions(filteredComps);
      },
    });

  /* HANDLE COMBINED FETCH RESULTS */
  useEffect(() => {
    if (euCompetitions?.length > 0 && auCompetitions?.length > 0) {
      const combinedResults = [...euCompetitions, ...auCompetitions];
      const sortedCombinedResults = combinedResults.sort(
        (a, b) =>
          (new Date(a.startDate).getTime() || -Infinity) -
          (new Date(b.startDate).getTime() || -Infinity)
      );
      sortedCombinedResults?.length > 0 &&
        setCombinedCompetitions(sortedCombinedResults);
    } else if (euCompetitions?.length > 0 && auCompetitions?.length < 1) {
      setCombinedCompetitions(euCompetitions);
    } else if (euCompetitions?.length < 1 && auCompetitions?.length > 0) {
      setCombinedCompetitions(auCompetitions);
    }
  }, [euCompetitions, auCompetitions]);

  /* HANDLE REGION CHANGE */
  useEffect(() => {
    if (selectedRegion === 1) {
      euCompetitionFetch();
      auCompetitionFetch();
    } else filteredFetch();
  }, [selectedRegion]);

  const loading = (!combinedCompetitions && euLoading) || auLoading;

  return (
    <Grid item xs={12} md={6} sx={{ bgcolor: "transparent" }}>
      <Box
        sx={{
          py: 1,
          bgcolor: "",
          background: "linear-gradient(#49647A, #374B5C)",
        }}
      >
        <Typography
          mx={1}
          my="auto"
          fontSize="1.2rem"
          fontWeight={600}
          color="#FFF"
        >
          {handleHeader()}
        </Typography>
      </Box>
      <Divider />
      <Box>
        <QueryResult
          loading={loading}
          data={
            selectedRegion === 1 ? combinedCompetitions : filteredCompetitions
          }
        >
          {selectedRegion === 1 &&
            combinedCompetitions?.length < 1 &&
            euCompetitions?.length > 0 &&
            euCompetitions?.map((competition, index) => (
              <Box
                key={competition?.id}
                sx={{
                  background:
                    index % 2
                      ? "#FFF"
                      : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                }}
              >
                <CompetitionCard competition={competition} index={index} />
              </Box>
            ))}
          {selectedRegion === 1 &&
            combinedCompetitions?.length < 1 &&
            auCompetitions?.length > 0 &&
            auCompetitions?.map((competition, index) => (
              <Box
                key={competition?.id}
                sx={{
                  background:
                    index % 2
                      ? "#FFF"
                      : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                }}
              >
                <CompetitionCard competition={competition} index={index} />
              </Box>
            ))}
          {selectedRegion === 1 &&
            combinedCompetitions?.length > 0 &&
            combinedCompetitions?.map((competition, index) => (
              <Box
                key={competition?.id}
                sx={{
                  background:
                    index % 2
                      ? "#FFF"
                      : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                }}
              >
                <CompetitionCard competition={competition} index={index} />
              </Box>
            ))}
          {selectedRegion === 1 && combinedCompetitions?.length < 1 && (
            <NoCompetitionsLink title="No Upcoming Competitions." />
          )}
          {selectedRegion !== 1 &&
            filteredCompetitions?.length > 0 &&
            filteredCompetitions?.map((competition, index) => (
              <Box
                key={competition?.id}
                sx={{
                  background:
                    index % 2
                      ? "#FFF"
                      : "linear-gradient(#E4E7EC, #EBEEF2, #E4E7EC)",
                }}
              >
                <CompetitionCard competition={competition} index={index} />
              </Box>
            ))}
          {selectedRegion !== 1 && filteredCompetitions?.length < 1 && (
            <NoCompetitionsLink title="No Upcoming Competitions." />
          )}
        </QueryResult>
      </Box>
    </Grid>
  );
}
