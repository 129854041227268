import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Box,
    Divider,
    Typography,
} from '@mui/material';

import { BsPeopleFill } from 'react-icons/bs';

export default function ClubListItem({ clubListItem, displayProps, setClub, competitors, setCompetitors, setSelectedCompetitor, view, setView, clearSearchInput }) {

    const [imgLoaded, setImgLoaded] = useState(true);

    const params = useParams();
    const navigate = useNavigate();

    return (
        <Box
            display='flex'
            py={0.5}
            gap={1}
            onClick={() => {
                setSelectedCompetitor(null);
                setClub(clubListItem);
                setCompetitors(competitors);
                setView(1);
                clearSearchInput();
                navigate(`../competitions/${params.competitionName}/swimmers/clubs/${clubListItem?.oid}`);
            }}
            sx={{ ...displayProps, cursor: view !== 1 && 'pointer' }}
        >
            <Box width={40} display='flex' ml='20px' justifyContent='center' alignItems='center' sx={{ bgcolor: '' }}>
                <Box component='img' height='32px' width='32px'
                    src={`${process.env.REACT_APP_CDN_URL}club_logos/${clubListItem?.country_code.toLowerCase()}/32/${clubListItem?.short_name.toLowerCase()}.png`} onError={() => setImgLoaded(false)}
                    sx={{ display: imgLoaded ? 'inline' : 'none' }}
                />
                {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
                {!imgLoaded &&
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        height='32px'
                        width='32px'
                        sx={{ bgcolor: '#374B5C', borderRadius: 2, }}>
                        <BsPeopleFill color='#FFF' />
                    </Box>
                }
            </Box>
            <Divider orientation='vertical' variant='middle' flexItem />
            <Box display='flex' flexDirection='column' justifyContent='center' gap={0.25} sx={{ bgcolor: '', width: 'calc(100% - 72px)' }}>
                <Typography fontWeight={500} fontSize='0.9rem' noWrap mr='8px' sx={{ bgcolor: '' }}>
                    {clubListItem?.name}
                </Typography>
            </Box>
        </Box>
    )
}
