import { useState, useContext, useMemo, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";
import { BsPeopleFill } from "react-icons/bs";
import SubResults from "../SubResults";
import RelayHeatSubResults from "./RelayHeatSubResults";
import {
  competitorNavigationString,
  heatTimeDisplay,
  heatRankDisplay,
  heatLaneEntryTime,
  getBirthYear,
} from "../../../../../components/utils";
import {
  EntryResultDiff2,
  FlagComponent,
} from "../../../../../components/layout";
import { CountryCode } from "../../../../../components/layout/FlagComponent";
import { ScreenContext } from "../../../../../context/ScreenContext";
import { determineParaCode } from "../../../../../components/utils/determineParaCodes";

export default function HeatLane({
  eventType,
  lane,
  event,
  index,
  heatStatus,
  eventLevel,
  showAge,
}: {
  eventType: number | undefined;
  lane: Lane;
  event: Event | undefined;
  index: number;
  heatStatus: number;
  eventLevel: number;
  showAge: boolean | undefined;
}) {
  const { setScreen } = useContext(ScreenContext);
  const navigate = useNavigate();
  const params = useParams();

  const oldRank = useRef<number | undefined>();

  /* const [result, setResult] = useState<Lane | undefined>(); */
  const [animateResult, setAnimateResult] = useState<number>(0);
  const [animateRank, setAnimateRank] = useState<number>(0);
  const [imgLoaded, setImgLoaded] = useState(true);

  const initialRender = useRef(true);

  useEffect(() => {
    initialRender.current = false;
  }, []);

  useMemo(() => {
    if (lane.result_text && initialRender.current === false) {
      setAnimateResult(1);
    }
  }, [lane.result_text]);

  useMemo(() => {
    if (lane.heat_rank !== oldRank.current) {
      if (initialRender.current === false) {
        setAnimateRank(1);
      }

      oldRank.current = lane.heat_rank;
    }
  }, [lane.heat_rank]);

  /* useMemo(() => {
    if (lane.result_text != result?.result_text) {
      setResult(lane);
    }
  }, [lane]); */

  //TEST ANIMATION

  // useEffect(()=> {
  //   (lane.number === 2 || lane.number === 3 || lane.number === 4 || lane.number === 5) && setInterval(() => {
  //     setAnimateResult(1)
  //   }, 5000)
  // }, []) 

  // useEffect(()=> {
  //   (lane.number === 2 || lane.number === 3 || lane.number === 4 || lane.number === 5) && setInterval(() => {
  //     setAnimateRank(1)
  //   }, 5000)
  // }, []) 

  // useEffect(() => {
  //   (lane.number === 2 || lane.number === 3 || lane.number === 4 || lane.number === 5) && setInterval(() => {
  //     (lane.number === 2) && setTimeout(() => {

  //       setAnimateResult(1);
  //     }, 4450);
  //     (lane.number === 3) && setTimeout(() => {

  //       setAnimateResult(1);
  //     }, 4000);
  //     (lane.number === 4) && setTimeout(() => {

  //       setAnimateResult(1);
  //     }, 4200);
  //     (lane.number === 5) && setTimeout(() => {

  //       setAnimateResult(1);
  //     }, 4400);
  //   }, 5000)
  // }, []); 


  // useEffect(() => {
  //   (lane.number === 2 || lane.number === 3 || lane.number === 4 || lane.number === 5) && setInterval(() => {
  //     (lane.number === 2 || lane.number === 3 || lane.number === 4 || lane.number === 5) && setTimeout(() => {

  //       setAnimateRank(1);
  //     }, 4450);
  //     (lane.number === 2 || lane.number === 3 || lane.number === 4 || lane.number === 5) && setTimeout(() => {

  //       setAnimateRank(1);
  //     }, 4000);
  //     (lane.number === 2 || lane.number === 3 || lane.number === 4 || lane.number === 5) && setTimeout(() => {

  //       setAnimateRank(1);
  //     }, 4200);
  //     (lane.number === 2 || lane.number === 3 || lane.number === 4 || lane.number === 5) && setTimeout(() => {

  //       setAnimateRank(1);
  //     }, 4400);
  //   }, 5000)
  // }, []); 

  // TEST ANIMATION

  return (
    <Grid
      container
      item
      xs={12}
      py={0.5}
      alignItems="center"
      onClick={() => {
        navigate(
          competitorNavigationString(
            params.competitionName,
            lane?.competitor?.club?.oid,
            lane?.competitor?.oid
          )
        );
        setScreen("swimmers");
      }}
      //@ts-ignore
      sx={{
        bgcolor: "#FFF",
        cursor: "pointer",
        borderTopStyle: index !== 0 && "solid",
        borderWidth: 1,
        borderColor: "#D0D5DD",
      }}
    >
      <Box display="flex" width={36} ml="4px" sx={{ bgcolor: "" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="32px"
          width="32px"
          sx={{
            bgcolor: "#F4C647",
            borderRadius: 2,
          }}
        >
          <Typography>{lane?.number}</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        width={16}
        height={12}
        ml="6px"
        mb="1px"
        sx={{ boxShadow: 2, borderRadius: 1, bgcolor: "" }}
      >
        <FlagComponent
          countryCode={lane?.competitor?.country_code as CountryCode}
          size="s"
        />
      </Box>
      <Box
        display="flex"
        width={eventLevel > 1 ? "calc(100% - 434px)" : "calc(100% - 518px)"}
        maxWidth="240px"
        ml="6px"
        sx={{ bgcolor: "" }}
      >
        <Typography fontWeight={500} noWrap>
          {lane?.competitor?.full_name}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={40}
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        {eventType &&
          lane?.competitor &&
          determineParaCode(eventType, lane.competitor) && (
            <Typography lineHeight={1} fontSize={"0.7em"} mt={"2px"} mr={"8px"}>
              {determineParaCode(eventType, lane.competitor)}
            </Typography>
          )}
        <Typography fontSize="0.9rem" lineHeight={1}>
          {event?.is_relay
            ? lane?.competitor?.age
            : showAge
              ? lane?.competitor?.age
              : getBirthYear(lane?.competitor?.birthday)}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        minWidth="90px"
        width={eventLevel > 1 ? "calc(100% - 584px)" : "calc(100% - 668px)"}
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        <Box
          component="img"
          height="32px"
          width="32px"
          src={`${process.env.REACT_APP_CDN_URL
            }club_logos/${lane?.competitor?.club?.country_code?.toLowerCase()}/32/${lane?.competitor?.club?.short_name?.toLowerCase()}.png`}
          onError={() => setImgLoaded(false)}
          sx={{ display: imgLoaded ? "inline" : "none" }}
        />
        {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
        {!imgLoaded && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="32px"
            minWidth="32px"
            sx={{ bgcolor: "#374B5C", borderRadius: 2 }}
          >
            <BsPeopleFill color="#FFF" size="24px" />
          </Box>
        )}
        <Typography noWrap fontSize="0.9rem" ml="8px" lineHeight={"normal"}>
          {lane?.competitor?.club?.name}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={28}
        ml="8px"
        sx={{ bgcolor: "" }}
      >
        <Typography fontSize="0.7rem">{lane?.reaction_time}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        width={32}
        ml="8px"
        sx={{ bgcolor: "", display: eventLevel > 1 ? "none" : "inherit" }}
      >
        <Typography fontSize="0.7rem">
          {!event?.is_para_event
            ? lane?.fina_points !== 0
              ? lane?.fina_points
              : ""
            : event?.is_para_event && lane?.wps_points !== 0
              ? lane?.wps_points
              : ""}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        width={36}
        ml="8px"
        sx={{ bgcolor: "", display: eventLevel > 1 ? "none" : "inherit" }}
      >
        {(lane.heat_rank !== 0 || lane.heat_rank === 0) &&
          heatStatus &&
          heatRankDisplay(heatStatus, lane, lane?.heat_rank) ? (
            <Box
              className="heat-rank"
              onAnimationEnd={() => setAnimateRank(0)}
              data-animationon={animateRank}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="24px"
              width="24px"
              color="#ffffff"
              sx={{
                bgcolor: "#374B5C",
                borderRadius: 1,
              }}
            >
              <Typography>
                {heatRankDisplay(heatStatus, lane, lane?.heat_rank)}
              </Typography>
            </Box>
          ) : ''}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="end"
        justifyContent="space-between"
        width={80}
        height="32px"
        ml="4px"
        sx={{ bgcolor: "" }}
      >
        <Typography
          className="heat-result"
          onAnimationEnd={() => setAnimateResult(0)}
          data-animationon={animateResult}
          display="flex"
          justifyContent="flex-end"
          lineHeight={1}
          width={72}
          fontSize="1rem"
          fontWeight={500}
          sx={{
            bgcolor: "",
            width: "fit-content",
            borderRadius: "4px",
            paddingLeft: "4px",
            paddingRight: "4px",
            paddingTop: "1px",
            paddingBottom: "1px",
          }}
        >
          {heatStatus >= 0 && heatTimeDisplay(heatStatus, lane)
            ? heatTimeDisplay(heatStatus, lane)
            : ""}
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={80}
          paddingRight="4px"
          sx={{ bgcolor: "" }}
        >
          <Typography lineHeight={1} fontWeight={300} fontSize="0.7rem">
            {heatLaneEntryTime(lane)}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="flex-end"
        width={60}
        height="28px"
        ml="4px"
        sx={{ bgcolor: "" }}
      >
        <Typography
          noWrap
          width="auto"
          ml="4px"
          maxWidth={55}
          lineHeight={1}
          fontSize="0.8rem"
        >
          {lane?.note ? lane.note : ""}
          {lane.note &&
            (lane.not_in_competition || lane.competitor?.not_in_competition) &&
            ", "}
          {(lane.not_in_competition || lane.competitor?.not_in_competition) &&
            "EXH"}
        </Typography>
        <Typography
          component={"div"}
          lineHeight={1}
          fontWeight={300}
          fontSize="0.7rem"
          ml="4px"
        >
          {lane?.result_value &&
            lane?.entry_time &&
            !(lane.dsq || lane.dns || lane.dnf) ? (
            <EntryResultDiff2 lane={lane} />
          ) : (
            ""
          )}
        </Typography>
      </Box>
      <Box
        display="flex"
        width={18}
        height="28px"
        alignItems="start"
        ml="8px"
        mr="8px"
        sx={{ bgcolor: "" }}
      >
        <Typography lineHeight={1} fontSize="0.8rem">
          {lane?.qualification}
        </Typography>
      </Box>
      {event?.is_relay && (
        <RelayHeatSubResults
          lane={lane}
          event={event}
          heatStatus={heatStatus}
          showAge={showAge}
        />
      )}
      {!event?.is_relay &&
        lane.sub_results &&
        event?.distance &&
        lane?.sub_results[0]?.split_diff_text !== "" &&
        event?.distance > 50 && (
          <SubResults
            lane={lane}
            distance={event?.distance}
            eventLevel={eventLevel}
            initialRender={initialRender.current}
          />
        )}
    </Grid>
  );
}
