import React, { useState, useEffect, useContext, useMemo } from "react";

import { Box, Typography, Grid } from "@mui/material";

import { FaSwimmer } from "react-icons/fa";

import ReactGA from "react-ga4";

import QueryResult from "../../../components/containers/QueryResult";
import EventInfo from "./components/EventInfo";
import LiveHeat from "./components/LiveHeat";
import LiveTable from "./components/LiveTable";

import { ScreenContext } from "../../../context/ScreenContext";
import useCalculateSplits from "../../../hooks/useCalculateSplits";
import useScoreboardCurrentHeat from "../../../hooks/useScoreboardCurrentHeat";
import useCompetitionId from "../../../hooks/useCompetitionId";
import { SeoContext } from "../../../context/SeoContext";
import { CurrentHeatContext } from "../../../context/CurrentHeatContext";

export interface Splits {
  "50": number;
  "100": number;
  "200": number;
}

interface ScoreboardProps {
  // currentHeatLoading: boolean,
  selectedCurrentHeat: CurrentHeatSelection;
  //   selectedCurrentHeatChanged: boolean,
  // aboutToUpdate: boolean,
  // trySplits: (splits: Splits) => void,
  // lowestSplits: Splits,
  // heat: Heat | undefined,
  showAge: boolean | undefined;
}

export type CurrentHeatSelection = "Current" | "Next" | "Prev";

export default function ScoreboardView({
  showAge,
  selectedCurrentHeat,
}: ScoreboardProps) {
  const { competitionSEO } = useContext(SeoContext);
  const { aboutToUpdate } = useContext(CurrentHeatContext);
  const maxSplit: Splits = {
    "50": Number.MAX_SAFE_INTEGER,
    "100": Number.MAX_SAFE_INTEGER,
    "200": Number.MAX_SAFE_INTEGER,
  };

  const [lowestSplits, setLowestSplits] = useState<Splits>(maxSplit);
  const { trySplits, resetSplits, retryLastSplits } = useCalculateSplits({
    lowestSplits: lowestSplits,
    setLowestSplits: setLowestSplits,
  });
  const { competitionId } = useCompetitionId(competitionSEO);

  const {
    loading: useCurrent_HeatLoading,
    heat,
    selectedCurrentHeatChanged,
    setSelectedCurrentHeat,
    isActive,
  } = useScoreboardCurrentHeat(
    competitionId,
    resetSplits,
    retryLastSplits,
    "scoreboard" as View
  );

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [displayHeat, setDisplayHeat] = useState<Heat | undefined>(undefined);
  const { screen } = useContext(ScreenContext);

  useEffect(() => {
    ReactGA.set({ page_title: `${screen} - ${selectedCurrentHeat}` });
    if (!isFirstRender) {
      ReactGA.send("pageview");
    }
    setIsFirstRender(false);
  }, [screen, selectedCurrentHeat, selectedCurrentHeatChanged]);

  useMemo(() => {
    setSelectedCurrentHeat(selectedCurrentHeat);
  }, [selectedCurrentHeat]);

  useEffect(() => {
    if (aboutToUpdate === false) {
      setTimeout(() => {
        document
          .getElementById("live-heat-box")
          ?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [screen, aboutToUpdate]);

  useEffect(() => {
    if (!useCurrent_HeatLoading && !aboutToUpdate) {
      setDisplayHeat(heat);
    }
  }, [heat, aboutToUpdate, useCurrent_HeatLoading]);

  return (
    <Box width="100%" sx={{ bgcolor: "" }}>
      <Box mx={0} my={0} sx={{ bgcolor: "" }}>
        <Grid container my={0} display="flex">
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            my="auto"
            width="100%"
            gap={1}
            py={1}
            sx={{
              color: "#FFF",
              bgcolor: "#374B5C",
              borderRadius: "6px 6px 0px 0px",
              boxShadow: 2,
            }}
          >
            <Box
              ml={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ bgcolor: "", top: "2px", position: "relative" }}
            >
              <FaSwimmer size="1.25rem" />
            </Box>
            <Typography
              fontWeight={400}
              fontSize={{ xs: "1rem", sm: "1.1rem", md: "1.25rem" }}
            >
              {selectedCurrentHeat === "Current"
                ? "Current Heat"
                : selectedCurrentHeat === "Next"
                ? "Upcoming Heat"
                : "Previous Heat"}
            </Typography>
          </Box>
        </Grid>
        <QueryResult
          onlyLoadInitially
          loading={useCurrent_HeatLoading}
          data={displayHeat}
        >
          {displayHeat ? (
            <div id="scoreboard">
              <EventInfo liveHeat={displayHeat} />
              <LiveHeat
                eventType={
                  displayHeat?.time_program_entry?.round?.event?.event_type
                }
                heat={displayHeat}
                showAge={showAge}
                isActive={isActive}
              />
              <LiveTable
                selectedCurrentHeatChanged={selectedCurrentHeatChanged}
                aboutToUpdate={aboutToUpdate}
                heat={displayHeat}
                lowestSplits={lowestSplits}
                trySplits={trySplits}
              />
            </div>
          ) : (
            <Grid
              container
              item
              justifyContent="center"
              alignItems="auto"
              xs={12}
            >
              <Typography p={1} variant="h4">
                {selectedCurrentHeat === "Current"
                  ? " No ongoing heat"
                  : selectedCurrentHeat === "Next"
                  ? "No upcoming Heat"
                  : "No previous Heat"}
              </Typography>
            </Grid>
          )}
        </QueryResult>
      </Box>
    </Box>
  );
}
