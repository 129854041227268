import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { GET_CURRENT_TIME } from "../utils/graphql/queries";

interface CompetitionDetailsProperties {
  isBeta: boolean;
  setIsBeta: (boolean: boolean) => void;
  shouldSubscribe: boolean;
  updateShouldSubscribe: (boolean: boolean) => void;
  serverTimeDiff: number;
}

export const CompetitionDetailsContext =
  React.createContext<CompetitionDetailsProperties>({
    isBeta: false,
    setIsBeta: () => {},
    shouldSubscribe: false,
    updateShouldSubscribe: () => {},
    serverTimeDiff: 0,
  });

export default function CompetitionDetailsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isBeta, setIsBeta] = useState<boolean>(false);
  const [shouldSubscribe, setShouldSubscribe] = useState<boolean>(false);
  const [diff, setDiff] = useState<number>(0);

  const { loading, error, data } = useQuery(GET_CURRENT_TIME, {
    onCompleted(data) {
      const clientTime = new Date().getTime();
      const serverTime = new Date(data.get_current_time[0].time).getTime();
      const difference = clientTime - serverTime;
      setDiff(difference);
    },
  });

  const updateShouldSubscribe = (val: boolean) => {
    setShouldSubscribe(val);
  };
  return (
    <CompetitionDetailsContext.Provider
      value={{
        isBeta: isBeta,
        setIsBeta: (val: boolean) => {
          setIsBeta(val);
        },
        shouldSubscribe: shouldSubscribe,
        updateShouldSubscribe: updateShouldSubscribe,
        serverTimeDiff: diff,
      }}
    >
      {children}
    </CompetitionDetailsContext.Provider>
  );
}
