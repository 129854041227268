import { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
    Box,
    Typography,
    Grid,
} from '@mui/material';
import { BsPersonFill } from 'react-icons/bs';
import { BsPeopleFill } from 'react-icons/bs';
import { BsDot } from 'react-icons/bs';
import ReactGA from "react-ga4";
import { GET_COMPETITOR_HEATS } from '../../../../utils/graphql/queries';
import QueryResult from '../../../../components/containers/QueryResult';
import { FlagComponent } from '../../../../components/layout';
import { CountryCode } from '../../../../components/layout/FlagComponent';
import SwimmerHeatCard from './components/SwimmerHeatCard';
import SwimmerEntryList from './components/SwimmerEntryList';
import { getBirthYear } from '../../../../components/utils';
import './components/swimmerDetails.css';

export default function CompetitionSwimmerDetails({ competitionId, swimmerId, showAge }: { competitionId: string, swimmerId: string | null, showAge: boolean | undefined; }) {

    ReactGA.send({hitType: "pageview", page: "/competitions/competition/swimmers/clubs/num/competitior/", title: "swimmer details"});

    const [imgLoaded, setImgLoaded] = useState<boolean>(true);
    const [filteredEntries, setFilteredEntries] = useState<Entry[]>([]);

    const filterCompetitorEntries = (entries: Entry[], heats: Heat[]) => {
        const eventHeatNumbers = heats?.map(heat => heat?.round?.event?.number);
        const filterEntries: Entry[] = [];
        entries.forEach(entry => {
            eventHeatNumbers.forEach(evtHeatNum => {
                if (entry?.event?.number !== evtHeatNum) {
                    filterEntries.push(entry);
                }
            });
        });
        setFilteredEntries(filterEntries);
    };

    const getHeatsFromLanesArr = (lanes: Lane[]): Heat[] => {
        const heats: Heat[] = [];
        lanes.forEach(lane => {
            lane.heat && heats.push(lane.heat);
        });
        return heats;
    };

    const { loading, error, data } = useQuery<Record<'competitor', Competitor[]>>(GET_COMPETITOR_HEATS, {
        variables: {
            oid: swimmerId,
            competitionId: competitionId
        },
        onCompleted: data => {
            const heats: Heat[] = data?.competitor && data?.competitor[0]?.lanes ? getHeatsFromLanesArr(data?.competitor[0].lanes) : [];
            data?.competitor && data.competitor[0].entries && data.competitor[0].lanes && filterCompetitorEntries(data?.competitor[0]?.entries, heats);
        }
    });

    const competitor = data?.competitor[0];

    return (
        <>
            <QueryResult error={error} loading={loading} data={data}>
                <Grid container item display='flex' justifyContent='flex-start' py={1} mb={1} sx={{
                    borderRadius: 1,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: '#D0D5DD',
                    marginBottom: 1,
                    boxShadow: 1,
                }}>
                    <Grid item xs={12} display='flex' flexDirection={{ xs: 'row', md: 'column' }} justifyContent={{ xs: 'center', md: 'flex-start' }} alignItems={{ xs: 'center', md: 'flex-start' }} sx={{ bgcolor: '' }}>

                        {/* SWIMMER */}
                        <Box display='flex' alignItems='center' mx={1} mb={0.5} sx={{ bgcolor: '' }}>
                            <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                height='40px'
                                width='40px'
                                sx={{ bgcolor: '#374B5C', borderRadius: 2, }}>
                                <BsPersonFill color='#FFF' size='28px' />
                            </Box>
                            <Typography ml={1} fontWeight={600} fontSize={{ xs: '1rem', sm: '1.2rem', md: '1.8rem' }}>{competitor?.full_name}</Typography>
                            <BsDot size='1.8rem' />
                            <Typography fontWeight={600} fontSize={{ xs: '1rem', sm: '1.2rem', md: '1.8rem' }}>{showAge ? competitor?.age : getBirthYear(competitor?.birthday)}</Typography>
                        </Box>
                        {/* CLUB */}
                        <Box display='flex' alignItems='center' mb={0.75}>
                            <Box component='img' height='40px' width='40px' ml={1} src={`${process.env.REACT_APP_CDN_URL}club_logos/${competitor?.club?.country_code?.toLowerCase()}/32/${competitor?.club?.short_name?.toLowerCase()}.png`} onError={() => setImgLoaded(false)} sx={{ display: imgLoaded ? 'inline' : 'none' }} />
                            {/* IF CLUB LOGO DOESN'T LOAD, RENDER BELOW ELEMENT */}
                            {!imgLoaded &&
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    height='40px'
                                    width='40px'
                                    ml={1}
                                    sx={{ bgcolor: '#374B5C', borderRadius: 2, }}>
                                    <BsPeopleFill color='#FFF' size='28px' />
                                </Box>
                            }
                            <Typography mx={1} fontWeight={500} fontSize={{ xs: '0.6rem', sm: '0.8rem', md: '1rem' }}>{competitor?.club?.name}</Typography>
                        </Box>
                        {/* COUNTRY */}
                        <Box display='flex' alignItems='center'>
                            <Box display='flex' justifyContent='center' alignItems='center' ml={1} width='40px'>
                                <Box width='32px' display='flex' justifyContent='center' alignItems='center' height={{ xs: 15, sm: 24 }} sx={{ boxShadow: 0, borderRadius: 2, bgcolor: '' }}>
                                    <FlagComponent countryCode={data?.competitor[0]?.country_code as CountryCode} size='l' />
                                </Box>
                            </Box>
                            <Typography mx={1} fontWeight={500} fontSize={{ xs: '0.6rem', sm: '0.8rem', md: '1rem' }}>{data?.competitor[0]?.country_code}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                {/* EITHER SHOW BOTH ENTRIES AND HEATS OR LET HEATS REPLACE CORRESPONDING ENTRIES */}
                {competitor?.lanes && competitor?.entries && competitor && competitor?.lanes?.map((lane, index) => (
                    <SwimmerHeatCard key={lane.id} lane={lane} index={index} entry={competitor.entries && competitor.entries[index]} competitor={competitor} />
                    // <SwimmerHeatCard key={lane.id} lane={lane} index={index}  />
                ))}
                {/* {filteredEntries?.length > 0 &&
                    <SwimmerEntryList entries={filteredEntries} />
                } */}
                {competitor && data?.competitor[0]?.entries?.length && data?.competitor[0]?.entries?.length > 0 &&
                    <SwimmerEntryList entries={data?.competitor[0]?.entries} competitor={competitor} />
                }
            </QueryResult>
        </>
    );
}
