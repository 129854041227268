import { useState, useEffect, useLayoutEffect, useMemo } from "react";

import { Box, Typography, Grid } from "@mui/material";

import LiveLane from "./LiveLane";
import { Splits } from "../ScoreboardView";

import styled from "styled-components";

export default function LiveTable({
  selectedCurrentHeatChanged,
  aboutToUpdate,
  heat,
  lowestSplits,
  trySplits,
}: {
  selectedCurrentHeatChanged: boolean;
  aboutToUpdate: boolean;
  heat: Heat;
  lowestSplits: Splits;
  trySplits: (splits: Splits) => void;
}) {
  const [classes, setClasses] = useState<string>(" relative");

  const tpe = heat?.time_program_entry;
  const distance = tpe?.round?.event?.distance;
  const lanesQuantity = heat?.lanes?.length;

  /* useLayoutEffect(() => {
    if (aboutToUpdate && !isAnimating) {
      //Animate out table underneath after 7400ms
      setIsAnimating(true);
      /*  setTimeout(() => {
                setClasses('table');
                setIsAnimating(false);
            }, 7400);
    } else {
      //Animate in table from underneath
      // !isAnimating && setClasses('table animating');
      //Set table relative
      /*   setTimeout(() => {
                setIsAnimating(true);
                setClasses('relative');
                setIsAnimating(false);
            }, 350);
    }

    return () => setClasses("table");
  }, [aboutToUpdate]);
*/
  return (
    <Table>
      <Grid
        container
        className={classes}
        display="flex"
        width="100%"
        sx={{
          justifyContent: "start",
          borderRadius: "0px 0px 6px 6px",
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#D0D5DD",
          bgcolor: "#E4E7EC",
          boxShadow: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            width: "2.5em",
          }}
          bgcolor={"#e4e7ec"}
        >
          <Typography
            sx={{
              height: "28px",
              pl: "3px",
              my: "1px",
              display: "flex",
              alignItems: "center",
              color: "rgb(102, 112, 133)",
            }}
            fontSize={"0.7rem"}
          >
            Name
          </Typography>
          <Typography
            sx={{
              height: "28px",
              pl: "3px",
              my: "1px",
              display: "flex",
              alignItems: "center",
              color: "rgb(102, 112, 133)",
            }}
            fontSize={"0.7rem"}
          >
            Lap
          </Typography>
          <Typography
            sx={{
              height: "28px",
              pl: "3px",
              my: "1px",
              display: "flex",
              alignItems: "center",
              color: "rgb(102, 112, 133)",
            }}
            fontSize={"0.7rem"}
          >
            50m
          </Typography>
          {distance && distance >= 100 && (
            <Typography
              sx={{
                height: "28px",
                pl: "3px",
                my: "1px",
                display: "flex",
                alignItems: "center",
                color: "rgb(102, 112, 133)",
              }}
              fontSize={"0.7rem"}
            >
              100m
            </Typography>
          )}
          {distance && distance >= 200 && (
            <Typography
              sx={{
                height: "28px",
                pl: "3px",
                my: "1px",
                display: "flex",
                alignItems: "center",
                color: "rgb(102, 112, 133)",
              }}
              fontSize={"0.7rem"}
            >
              200m
            </Typography>
          )}
        </Box>
        {distance &&
          heat.lanes?.map((lane) => (
            <LiveLane
              key={lane.id}
              distance={distance}
              lane={lane}
              lanesQuantity={lanesQuantity || 8}
              lowestSplits={lowestSplits}
              trySplits={trySplits}
            />
          ))}
      </Grid>
    </Table>
  );
}

const Table = styled.div`
    .table{
        position: absolute;
        top: 0;
        z-index: -10;
        transform: translateY(-12em);
        transition: all .3s ease-in-out;
        box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
        
        &.animating{
            transform:translateY(0px);
            transition: z-index 0s .3s, transform .3s ease-in-out;
        }
    }

    .relative{
        position:relative;
    }
`;
